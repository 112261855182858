import axios from 'app/client';
import { AppThunk } from 'app/store';
import * as appActions from './app.actions';

export const GET_MP_DEVICES_SUCCESS = 'GET_MP_DEVICES_SUCCESS';

export const getMpDevices = (): AppThunk => async dispatch => {
	try {
		const { data } = await axios.get(`/api/user/devices`);
		dispatch({
			type: GET_MP_DEVICES_SUCCESS,
			payload: {
				list: data
			}
		});
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};
