import React from 'react';
import { makePolicyEnforcer } from '@sec/policy-enforcer';
import axios from 'app/client';
import PolicyEnforcerContext from './PolicyEnforcerContext';

const fetchPolicy = async (): Promise<{ [key: string]: string[] }> => {
	try {
		const { data } = await axios.get('/api/license-policy');
		return data;
	} catch (e) {
		console.error(e);
		return {};
	}
};

const PolicyEnforcerContextProvider: React.FC = ({ children }) => {
	const [state, setState] = React.useState<Enforcer | null>(null);

	React.useEffect(() => {
		const _ = async () => {
			const enforcer = await makePolicyEnforcer(fetchPolicy);
			setState(enforcer);
		};
		_();
	}, []);

	return <PolicyEnforcerContext.Provider value={state}>{children}</PolicyEnforcerContext.Provider>;
};

export default PolicyEnforcerContextProvider;
