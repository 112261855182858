import i18next from 'i18next';
import _ from '@lodash';
import { User } from 'app/store/types';

export const splitName = (user: Omit<User, 'firstName' | 'lastName'> & { name: string }): User => {
	// remove `name` from user
	const { name, ...restOfUser } = user;

	const [firstName, lastName = ''] = name.split(/\s(.+)/);

	return {
		...restOfUser,
		firstName,
		lastName
	};
};

export const formatLicenseId = (licenseId: string | undefined) => {
	if (!licenseId?.length) {
		return '';
	}

	const sectionLength = licenseId.length > 16 ? 8 : 6;
	let formattedId = '';
	const split = licenseId.split('-');

	if (split.length > 1) {
		licenseId = split[1];
	}

	[...licenseId].forEach((char, idx) => {
		formattedId += char;
		if ((idx + 1) % sectionLength === 0) {
			formattedId += '-';
		}
	});

	if (formattedId.endsWith('-')) {
		formattedId = formattedId.slice(0, -1);
	}

	return formattedId;
};

export const arrayify = (value: any) => (Array.isArray(value) ? value : [value]);

// long: "11/2/2017, 12:13:42 PM"
// short: "11/2/2017"
// friendly: "November 2, 2017"
export const getLocalTime = (
	date: ConstructorParameters<DateConstructor>[0],
	display: 'long' | 'short' | 'friendly' = 'short'
) => {
	const options = {
		long: undefined,
		short: { year: 'numeric', month: 'numeric', day: 'numeric' } as const,
		friendly: { year: 'numeric', month: 'long', day: 'numeric' } as const
	}[display];

	return date ? new Date(date).toLocaleString(i18next.language, options) : undefined;
};

// FIXME::need to combine `responseError` and `responseErrors` or just...make this less nonsense
type ResponseErrors = { data: { errors?: { add?: string[]; delete?: string[] } } }[];
export const responseErrors = (responses: ResponseErrors) => {
	const errors = responses
		.map(({ data }) => (data.errors ? [...(data.errors.add || []), ...(data.errors.delete || [])] : []))
		.flat();
	if (errors.length) console.error(errors);
	return errors;
};

type ErrorOutput = {
	payload: {
		error: string;
		message: string;
		statusCode: number;
	};
	statusCode: number;
};
type ResponseError = { output: ErrorOutput }[] | null;
export const responseError = ({ errors }: { errors: ResponseError }) => {
	const error = errors?.[0].output;
	console.error(error);
	return error;
};

export const wait = (duration: number) => new Promise(fulfill => setTimeout(fulfill, duration));
