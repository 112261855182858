// use this reducer for UI and user activity state (not data from the backend)
import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import { RootState } from 'app/store';
import { AppState, AppActionTypes } from 'app/store/types';
import * as Actions from '../actions';

const initialState: AppState = {
	selectedLicenseGroupId: undefined!, // HACK::assume never undefined and handle check only in <LicenseGroupPageWrapper />
	selectedTransferLicenseGroupId: '',
	usersFilterText: '',
	devicesFilterText: '',
	hideNavbar: false,
	cookieSettingsAcknowledged: false,
	showCookieSettings: false,
	modifiedLicenseGroupPricing: {
		currentPrice: undefined,
		modifiedPrice: undefined
	},
	expiredLicense: false
};

const appReducer = (state = initialState, action: AppActionTypes) => {
	switch (action.type) {
		case Actions.SET_SELECTED_LICENSE_GROUP: {
			return {
				...state,
				selectedLicenseGroupId: action.payload.selectedLicenseGroupId
			};
		}
		case Actions.SET_USERS_FILTER_TEXT: {
			return {
				...state,
				usersFilterText: action.payload.usersFilterText
			};
		}
		case Actions.SET_DEVICES_FILTER_TEXT: {
			return {
				...state,
				devicesFilterText: action.payload.devicesFilterText
			};
		}
		case Actions.HIDE_NAVBAR: {
			return {
				...state,
				hideNavbar: action.payload.hide
			};
		}
		case Actions.ACKNOWLEDGE_COOKIE_NAGBAR: {
			return {
				...state,
				cookieSettingsAcknowledged: true
			};
		}
		case Actions.TOGGLE_COOKIE_SETTINGS_DIALOG: {
			return {
				...state,
				showCookieSettings: action.payload.showCookieSettings
			};
		}
		// wipe selectedLicenseGroupId if id not found in returned license groups list
		case Actions.GET_LICENSE_GROUPS_SUCCESS: {
			const prevSelectedLicenseGroupId = state.selectedLicenseGroupId as string | undefined; // HACK::simplifying type by just being string but it can be both here

			return {
				...state,
				selectedLicenseGroupId:
					prevSelectedLicenseGroupId && action.payload.data && action.payload.data[prevSelectedLicenseGroupId]
						? prevSelectedLicenseGroupId
						: undefined!
			};
		}
		case Actions.GET_MODIFIED_LICENSE_GROUP_PRICING_SUCCESS: {
			const { currentPrice, modifiedPrice } = action.payload;

			return {
				...state,
				modifiedLicenseGroupPricing: {
					currentPrice,
					modifiedPrice
				}
			};
		}
		case Actions.SET_EXPIRED_LICENSE: {
			return {
				...state,
				expiredLicense: action.payload.expired
			};
		}
		case Actions.SET_SELECTED_TRANSFER_LICENSE_GROUP: {
			return {
				...state,
				selectedTransferLicenseGroupId: action.payload.selectedLicenseGroupId
			};
		}
		case Actions.LOGGED_OUT_USER:
			return {
				...initialState,
				cookieSettingsAcknowledged: state.cookieSettingsAcknowledged,
				hideNavbar: state.hideNavbar
			};
		case Actions.PURGE_STATE:
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'app',
		storage: localForage,
		whitelist: ['selectedLicenseGroupId', 'cookieSettingsAcknowledged'] // wipe most app state on refresh
	},
	appReducer
);

// Selectors
export const getSelectedLicenseGroupId = ({ app }: RootState) => app.selectedLicenseGroupId;
export const getUsersFilterText = ({ app }: RootState) => app.usersFilterText;
export const getDevicesFilterText = ({ app }: RootState) => app.devicesFilterText;
export const getHideNavbar = ({ app }: RootState) => app.hideNavbar;
export const getCookieSettingsAcknowledged = ({ app }: RootState) => app.cookieSettingsAcknowledged;
export const getCookieSettings = ({ app }: RootState) => app.showCookieSettings;
export const getModifiedLicenseGroupPricing = ({ app }: RootState) => app.modifiedLicenseGroupPricing;
export const getIsExpired = ({ app }: RootState) => app.expiredLicense;
export const getSelectedTransferLicenseGroupId = ({ app }: RootState) => app.selectedTransferLicenseGroupId;
