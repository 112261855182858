import axios from 'app/client';
import { responseError } from 'app/utils/helpers';
import {
	getSelectedLicenseGroupId,
	getSelectedLicenseGroupVersion,
	getSelectedTransferLicenseGroupId,
	getLicenseData,
	getProfile
} from 'app/store/reducers';
import { AppThunk } from 'app/store';
import { LicenseGroupSettings, LicenseGroupData, PublicId } from 'app/store/types';
import * as appActions from './app.actions';
import { getDepartmentUi } from '../slice/departmentUISlice';

// at some point this may be broken into more specific API calls
export const GET_LICENSE_GROUPS_SUCCESS = 'GET_LICENSE_GROUPS_SUCCESS';
export const GET_LICENSE_GROUP_DATA_SUCCESS = 'GET_LICENSE_GROUP_DATA_SUCCESS';

export const SEND_TRANSFER_LICENSE_GROUP_DATA_SUCCESS = 'SEND_TRANSFER_LICENSE_GROUP_DATA_SUCCESS';
export const GET_TRANSFER_LICENSE_GROUP_DATA_SUCCESS = 'GET_TRANSFER_LICENSE_GROUP_DATA_SUCCESS';
export const ADD_DEVICE_TO_TRANSFER_LICENSE_GROUP = 'ADD_DEVICE_TO_TRANSFER_LICENSE_GROUP';
export const ADD_UI_TO_TRANSFER_LICENSE_GROUP = 'ADD_UI_TO_TRANSFER_LICENSE_GROUP';
export const ADD_USER_TO_TRANSFER_LICENSE_GROUP = 'ADD_USER_TO_TRANSFER_LICENSE_GROUP';
export const REMOVE_DEVICE_FROM_TRANSFER_LICENSE_GROUP = 'REMOVE_DEVICE_FROM_TRANSFER_LICENSE_GROUP';
export const REMOVE_UI_FROM_TRANSFER_LICENSE_GROUP = 'REMOVE_UI_FROM_TRANSFER_LICENSE_GROUP';
export const REMOVE_USER_FROM_TRANSFER_LICENSE_GROUP = 'REMOVE_USER_FROM_TRANSFER_LICENSE_GROUP';
export const CLEAR_TRANSFER_DATA = 'CLEAR_TRANSFER_DATA';

// HACK::publicId can come in upper- or lower-case
const normalizePublicId = (licenseGroupData: LicenseGroupData) => {
	if (licenseGroupData.catalogPublicId) {
		licenseGroupData.catalogPublicId = licenseGroupData.catalogPublicId.toUpperCase() as PublicId;
	}
	return licenseGroupData;
};

export const getLicenseGroups = (): AppThunk => async dispatch => {
	try {
		const { data } = await axios.get('/api/license-groups');

		dispatch({
			type: GET_LICENSE_GROUPS_SUCCESS,
			payload: {
				data: normalizePublicId(data)
			}
		});
	} catch (error) {
		if (error.response?.status === 401) {
			// if user not logged in
			dispatch({
				type: GET_LICENSE_GROUPS_SUCCESS,
				payload: {
					data: undefined
				}
			});
			return;
		}
		dispatch(appActions.handleError(error));
		// re-throw error for handling in <InitializeApp />
		throw error;
	}
};

export const getSelectedLicenseGroupData = (): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	try {
		const { data } = await axios.get(`/api/license-groups/${licenseGroupId}`);
		dispatch({
			type: GET_LICENSE_GROUP_DATA_SUCCESS,
			payload: {
				licenseGroupId,
				data: { ...data, details: normalizePublicId(data.details) }
			}
		});
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const leaveLicenseGroup = (): AppThunk => async (dispatch, getState) => {
	const userLicenseGroupData = getLicenseData(getState());
	// Should always have licensed by data
	// leaveLicenseGroup used on user settings page to leave licensed by group
	if (!userLicenseGroupData) {
		dispatch(appActions.alert('leave license group failed', 'warning'));
		return;
	}

	try {
		const licenseGroupId = userLicenseGroupData.id;
		const { data } = await axios.delete(`/api/license-groups/${licenseGroupId}`);

		if (data.success) {
			dispatch(appActions.alert('left license group successfully', 'success'));
			// redirect to the top
			setTimeout(() => {
				window.location.assign('/');
			}, 1200);
		} else {
			dispatch(appActions.alert('leave license group failed', 'warning'));
		}
	} catch (error) {
		dispatch(appActions.alert('leave license group failed', 'warning'));
	}
};

type TSettings = Partial<Omit<LicenseGroupSettings, 'domain'>>;
export const editSelectedLicenseGroup = (settings: TSettings): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const licenseGroupVersion = getSelectedLicenseGroupVersion(getState());

	try {
		const { data } = await axios.patch(`/api/license-groups/${licenseGroupId}/settings`, {
			version: licenseGroupVersion,
			...settings
		});

		if (responseError(data)?.statusCode === 400) {
			// if domain is taken or part of the banned list
			dispatch(appActions.alert('domain unavailable', 'warning'));
		} else {
			dispatch(appActions.alert('settings updated', 'success'));
		}
		dispatch(getSelectedLicenseGroupData());
	} catch (error) {
		if (error.response?.status === 400) {
			// if domain is taken or part of the banned list
			dispatch(appActions.alert('domain unavailable', 'warning'));
		} else {
			dispatch(appActions.handleError(error));
		}
	}
};

export const sendSelectedLicenseGroupVerifyDomainEmail = (email: string): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const licenseGroupVersion = getSelectedLicenseGroupVersion(getState());

	try {
		await axios.patch(`/api/license-groups/${licenseGroupId}/settings`, {
			version: licenseGroupVersion,
			pendingDomainAddress: email
		});
		dispatch(appActions.alert('confirmation email sent', 'success'));
		dispatch(getSelectedLicenseGroupData());
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const modifySelectedLicenseGroup = ({
	newPublicId,
	newCapacity
}: {
	newPublicId?: PublicId;
	newCapacity: number;
}): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const licenseGroupVersion = getSelectedLicenseGroupVersion(getState());

	try {
		await axios.post(`/api/license-groups/${licenseGroupId}/registration`, {
			version: licenseGroupVersion,
			capacity: newCapacity,
			...(newPublicId && { catalogItemId: newPublicId })
		});
		dispatch(appActions.alert('license plan updated', 'success'));
		dispatch(getSelectedLicenseGroupData());
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const getSelectedTransferLicenseGroupData = (): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedTransferLicenseGroupId(getState());
	try {
		const { data } = await axios.get(`/api/license-groups/${licenseGroupId}`);
		let departmentUIData = {};
		if (!(data.details.licenseName === 'Starter')) {
			const {
				data: { departmentUIs }
			} = await axios.get(`/api/license-groups/${licenseGroupId}/department-ui`);
			departmentUIData = departmentUIs;
		}

		dispatch({
			type: GET_TRANSFER_LICENSE_GROUP_DATA_SUCCESS,
			payload: {
				licenseGroupId,
				data: { ...data, details: normalizePublicId(data.details), uis: departmentUIData }
			}
		});
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

type TLicenseTransferData = {
	targetId: string;
	users?: string[];
	devices?: string[];
	uis?: string[];
};
export const sendTransferLicenseGroupData = (users: string[], devices: string[], uis: string[]): AppThunk => async (
	dispatch,
	getState
) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const targetLicenseGroupId = getSelectedTransferLicenseGroupId(getState());
	const transferData: TLicenseTransferData = {
		targetId: targetLicenseGroupId
	};

	transferData.users = users;
	transferData.devices = devices;
	transferData.uis = uis;

	try {
		await axios.patch(`/api/license-groups/${licenseGroupId}/transfer`, transferData);
		dispatch(appActions.alert('license group transfer success', 'success'));
		dispatch(getSelectedLicenseGroupData());
		dispatch(getDepartmentUi(true));
	} catch (error) {
		console.log('error sending transfer data');
		dispatch(appActions.handleError(error));
	}
};

export const addDeviceToTransferLicenseGroup = (devices: any) => {
	return {
		type: ADD_DEVICE_TO_TRANSFER_LICENSE_GROUP,
		payload: {
			devices
		}
	};
};

export const removeDeviceFromTransferLicenseGroup = (devices: string[]) => {
	return {
		type: REMOVE_DEVICE_FROM_TRANSFER_LICENSE_GROUP,
		payload: {
			devices
		}
	};
};

export const addUserToTransferLicenseGroup = (users: any) => {
	return {
		type: ADD_USER_TO_TRANSFER_LICENSE_GROUP,
		payload: {
			users
		}
	};
};

export const removeUserFromTransferLicenseGroup = (users: string[]) => {
	return {
		type: REMOVE_USER_FROM_TRANSFER_LICENSE_GROUP,
		payload: {
			users
		}
	};
};

export const addUiToTransferLicenseGroup = (uis: any) => {
	return {
		type: ADD_UI_TO_TRANSFER_LICENSE_GROUP,
		payload: {
			uis
		}
	};
};

export const removeUiFromTransferLicenseGroup = (uis: string[]) => {
	return {
		type: REMOVE_UI_FROM_TRANSFER_LICENSE_GROUP,
		payload: {
			uis
		}
	};
};

export const clearTransferData = () => {
	return {
		type: CLEAR_TRANSFER_DATA
	};
};
