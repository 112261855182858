import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import { RootState } from 'app/store';
import { ProfileState, ProfileActionTypes } from 'app/store/types';
import * as Actions from '../actions';

const initialState: ProfileState = {
	info: undefined!, // HACK::assume never undefined and handle check only in <LicenseGroupWrapper /> and <ToolbarLayoutAdmin />
	previousProfileId: undefined,
	resettingUi: undefined,
	licensePolicy: undefined
};

const profileReducer = (state = initialState, action: ProfileActionTypes) => {
	switch (action.type) {
		case Actions.GET_PROFILE_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				info: data?.user,
				previousProfileId: data?.user?.id ?? undefined,
				licensedBy: data?.licensedBy
			};
		}
		case Actions.PURGE_STATE:
			return initialState;
		case Actions.RESET_PERSONA_UI:
			return {
				...state,
				resettingUi: action.payload
			};
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'profile',
		storage: localForage,
		whitelist: ['previousProfileId'] // wipe user info on refresh
	},
	profileReducer
);

// Selectors
export const getProfile = ({ profile }: RootState) => profile.info;
export const getPreviousProfileId = ({ profile }: RootState) => profile.previousProfileId;
export const getIsLicensedProfile = ({ profile }: RootState) => !!profile.licensedBy?.id;
export const getProfileLicenseExpiration = ({ profile }: RootState) => profile?.licensedBy?.catalogItem?.expirationDate;
export const getIsExpiredLicense = ({ profile }: RootState) => !!profile?.licensedBy?.isExpired;
export const getLicenseData = ({ profile }: RootState) => profile?.licensedBy;
export const isLicenseGroupOwner = ({ profile, licenseGroups }: RootState): boolean => {
	const licenseGroupId = profile?.licensedBy?.id;
	const licenseGroup = licenseGroupId && licenseGroups?.byId ? licenseGroups?.byId[licenseGroupId] : null;
	return profile.info.id === licenseGroup?.owner || false;
};
