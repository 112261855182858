import localForage from 'localforage';
import _ from '@lodash';
import { persistReducer } from 'redux-persist';
import { splitName } from 'app/utils/helpers';
import { RootState } from 'app/store';
import { UsersState, UsersActionTypes } from 'app/store/types';
import { getSelectedLicenseGroupOwnerId } from 'app/store/reducers';
import * as Actions from '../actions';

const initialState: UsersState = {};

const usersReducer = (state = initialState, action: UsersActionTypes) => {
	switch (action.type) {
		case Actions.GET_LICENSE_GROUP_DATA_SUCCESS: {
			const {
				licenseGroupId,
				data: { users, pendingUsers }
			} = action.payload;

			return {
				...state,
				[licenseGroupId]: {
					byId: _.mapValues(users, user => splitName(user)),
					// actually keyed by email despite the name
					pendingById: _.mapValues(pendingUsers, pendingUser => ({ ...pendingUser, pending: true }))
				}
			};
		}
		case Actions.LOGGED_OUT_USER:
		case Actions.PURGE_STATE:
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'users',
		storage: localForage
	},
	usersReducer
);

// Selectors
export const getAllUsers = ({ app: { selectedLicenseGroupId }, users }: RootState) =>
	Object.values({
		...users[selectedLicenseGroupId].byId,
		...users[selectedLicenseGroupId].pendingById
	});

export const getUsers = ({ app: { selectedLicenseGroupId }, users }: RootState) =>
	Object.values(users[selectedLicenseGroupId].byId);

export const getUsersById = ({ app: { selectedLicenseGroupId }, users }: RootState) =>
	users[selectedLicenseGroupId].byId;

export const getPendingUsersById = ({ app: { selectedLicenseGroupId }, users }: RootState) =>
	users[selectedLicenseGroupId].pendingById;

// dev note::this assumes the owner must also be a member of the license group. If not errors will be thrown
export const getOwnerUser = (state: RootState) => getUsersById(state)[getSelectedLicenseGroupOwnerId(state)];
