import AppIcon from 'app/icons/AppIcon';
import React from 'react';
import { Widget } from 'app/store/types/panelEditor.types';
import HeartIcon from 'app/icons/HeartIcon';

export const PAGE_LIMIT = 4;

export const personalizeImages = [
	[
		`${process.env.PUBLIC_URL}/assets/images/panel-backgrounds/appreciate.jpg`,
		'ba345ab5ce458354564e8287c41250b522bb4c811915e5a535d6fe547f816bfe'
	],
	[
		`${process.env.PUBLIC_URL}/assets/images/panel-backgrounds/branch.jpg`,
		'f62c11cae29d9c9ddb7e52c4ca1180bb9d7ee48b32d2e3b75304534f9685d1b6'
	],
	[
		`${process.env.PUBLIC_URL}/assets/images/panel-backgrounds/brave.jpg`,
		'1813c0feb89e5478af620654663ced33a612e9ac91b35b6ab600731cbbdd77ee'
	],
	[
		`${process.env.PUBLIC_URL}/assets/images/panel-backgrounds/circuit.jpg`,
		'd00a70b3ffac07b8e40f5edae46d37f8f24816284df71ccca29541bc11e0fc8a'
	],
	[
		`${process.env.PUBLIC_URL}/assets/images/panel-backgrounds/confusion.jpg`,
		'a2b08629643ab7cc28f5af47fb2930ec3731f14f26324a2214d45cdce65b3fe9'
	],
	[
		`${process.env.PUBLIC_URL}/assets/images/panel-backgrounds/conservation.jpg`,
		'3451d2a7d19236106a2a523b455debf0517f7cef93dd009d091b3d1123989802'
	],
	[
		`${process.env.PUBLIC_URL}/assets/images/panel-backgrounds/echo.jpg`,
		'97aac2674463ff3b28967c460f3e3bade196ccecb65dfce86373db49865313d0'
	],
	[
		`${process.env.PUBLIC_URL}/assets/images/panel-backgrounds/heat.jpg`,
		'a4d12207106c4627bb24460ef37cbc8d609aef42dcd0057d3314bed21d5be51f'
	],
	[
		`${process.env.PUBLIC_URL}/assets/images/panel-backgrounds/prospect.jpg`,
		'd6634577322f670ee068312ef18c8eb93025ad20e2d221b49c9dd890a110abe0'
	],
	[
		`${process.env.PUBLIC_URL}/assets/images/panel-backgrounds/soft.jpg`,
		'5907adc38d747b4047ceea501b29fc9aba168b9ed2cae1e69a2c964086749ae0'
	],
	[
		`${process.env.PUBLIC_URL}/assets/images/panel-backgrounds/timber.jpg`,
		'40a78e106a9707c2bf00a36e59dc813eea6c6c1945683b42c78a7c6721b82a18'
	],
	[
		`${process.env.PUBLIC_URL}/assets/images/panel-backgrounds/understanding.jpg`,
		'51a138ed13936b9a99ed4c924727e77813d57e5d3f0b4d5a0cfe57b24ed148ad'
	]
];

export type RightPinningKeys = 'functions' | 'background' | 'language' | 'theme';

export const rightPinningTabs: {
	key: string;
	icon: string | React.ComponentType;
	titleTranslateKey: string;
	descriptionTranslateKey: string;
	component: React.LazyExoticComponent<any>;
}[] = [
	{
		key: 'startUp',
		icon: HeartIcon,
		titleTranslateKey: 'startUpTitle',
		descriptionTranslateKey: 'startUpDescription',
		component: React.lazy(() => import('./components/RightPin/StartUp'))
	},
	{
		key: 'functions',
		icon: AppIcon,
		titleTranslateKey: 'functionsTitle',
		descriptionTranslateKey: 'functionsDescription',
		component: React.lazy(() => import('./components/RightPin/FunctionTab'))
	},

	{
		key: 'background',
		icon: 'insert_photo',
		titleTranslateKey: 'backgroundTitle',
		descriptionTranslateKey: 'backgroundDescription',
		component: React.lazy(() => import('./components/RightPin/BackgroundTab'))
	},
	{
		key: 'language',
		icon: 'translate',
		titleTranslateKey: 'languageTitle',
		descriptionTranslateKey: 'languageDescription',
		component: React.lazy(() => import('./components/RightPin/Language'))
	},
	{
		key: 'theme',
		icon: 'palette',
		titleTranslateKey: 'themeTitle',
		descriptionTranslateKey: 'themeDescription',
		component: React.lazy(() => import('./components/RightPin/Theme'))
	}
];

export const themes = [
	{
		textBackground: 'rgba(250, 250, 250, 0.6)',
		fontColor: '#1E1E1E',
		iconBackground: 'rgba(230, 230, 230, 1)',
		backgroundColor: '#FAFAFA',
		name: 'theme:light theme'
	},
	{
		textBackground: '#333333',
		fontColor: '#FFFFFF',
		iconBackground: '#282828',
		backgroundColor: '#333333',
		name: 'theme:dark theme'
	},
	{
		textBackground: '#0B57B2',
		fontColor: '#FFFFFF',
		iconBackground: '#0A4EA0',
		backgroundColor: '#0B57B2',
		name: 'theme:blue theme'
	}
];

type TStartUpAppBuiltin = {
	type: 'builtin';
	value: string;
	key: string;
	translateKey: string;
	id?: string;
};

type TStartAppMPApp = {
	type: 'OapAppNo';
	value: {
		value: 'OapAppNo';
		app: Widget['||app||'];
	};
	key: string;
	translateKey: string;
};

export type TStartUpApp = TStartUpAppBuiltin | TStartAppMPApp;

export const startUpApps: TStartUpApp[] = [
	{
		type: 'builtin',
		value: 'WebUIHome',
		key: 'WebUIHome',
		translateKey: 'Home'
	},
	{
		type: 'builtin',
		value: 'WebUICopy',
		key: 'WebUICopy',
		translateKey: 'Copy',
		id: 'w00000001'
	},
	{
		type: 'builtin',
		value: 'WebUIFax',
		key: 'WebUIFax',
		translateKey: 'Fax',
		id: 'w00000007'
	},
	{
		type: 'builtin',
		value: 'ScanToEmail',
		key: 'ScanToEmail',
		translateKey: 'ScanToEmail',
		id: 'w00000002'
	},
	{
		type: 'builtin',
		value: 'ScanToBox',
		key: 'ScanToBox',
		translateKey: 'ScanToBox',
		id: 'w00000003'
	},
	{
		type: 'builtin',
		value: 'ScanToPC',
		key: 'ScanToPC',
		translateKey: 'ScanToPc',
		id: 'w00000004'
	},
	{
		type: 'builtin',
		value: 'Ifax',
		key: 'Ifax',
		translateKey: 'Internet Fax',
		id: 'w00000005'
	},
	{
		type: 'builtin',
		value: 'IPAddressFax',
		key: 'IPAddressFax',
		translateKey: 'IP Address Fax',
		id: 'w00000006'
	},
	{
		type: 'builtin',
		value: 'AuthPrint',
		key: 'AuthPrint',
		translateKey: 'Authentication Print'
	}
];
