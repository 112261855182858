import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Logo from 'app/fuse-layouts/shared-components/Logo';
import NavbarMobileToggleButton from 'app/fuse-layouts/shared-components/NavbarMobileToggleButton';
import UserMenu from 'app/fuse-layouts/shared-components/UserMenu';
import clsx from 'clsx';
import React from 'react';
import { useSelector, useDispatch } from 'app/modules/react-redux';
import { Link } from 'react-router-dom';
import * as Actions from 'app/store/actions';
import { getProfile, getSelectedLicenseGroupData, getLicenseGroups, getHideNavbar } from 'app/store/reducers';
import { useTranslation } from 'react-i18next';
// import LanguageSwitcher from '../../shared-components/LanguageSwitcher';

const useStyles = makeStyles(theme => ({
	separator: {
		width: 1,
		height: 64,
		backgroundColor: theme.palette.divider
	}
}));

function ToolbarLayoutAdmin({ adminPage = false }) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
	const toolbarTheme = useSelector(({ fuse }) => fuse.settings.toolbarTheme);

	const hideNavbar = useSelector(getHideNavbar);

	const licenseGroups = useSelector(getLicenseGroups) as ReturnType<typeof getLicenseGroups> | undefined; // HACK::use actual type here - assume never undefined within the wrapped app
	const licenseGroupData = useSelector(getSelectedLicenseGroupData) as
		| ReturnType<typeof getSelectedLicenseGroupData>
		| undefined; // HACK::use actual type here - assume never undefined within the wrapped app

	const profile = useSelector(getProfile) as ReturnType<typeof getProfile> | undefined; // HACK::use actual type here - assume never undefined within the wrapped app

	const classes = useStyles();

	return (
		<ThemeProvider theme={toolbarTheme}>
			<AppBar
				id="fuse-toolbar"
				className="flex relative z-10"
				color="default"
				style={{ backgroundColor: toolbarTheme.palette.background.default }}
			>
				<Toolbar className="container p-0 lg:px-24">
					{config.navbar.display && !hideNavbar && (
						<Hidden mdUp>
							<NavbarMobileToggleButton className="w-64 h-64 p-0" />
							<div className={classes.separator} />
						</Hidden>
					)}

					{/* TODO::can we add a way to get to the groups page without forgetting your selected group? */}
					<div className={clsx('flex flex-1 items-center')}>
						{adminPage && licenseGroupData && licenseGroups && licenseGroups.length > 1 && (
							<Button
								className="h-64 normal-case"
								onClick={() => dispatch(Actions.setSelectedLicenseGroup(undefined))}
							>
								<Icon>business</Icon>

								<div className="flex-col mx-12 items-start">
									<Typography component="span" className="normal-case font-600 flex">
										{licenseGroupData.name}
									</Typography>
									<Typography className="text-11" color="textSecondary">
										{licenseGroupData.domain.value}
									</Typography>
								</div>

								<Icon className="text-16">unfold_more</Icon>
							</Button>
						)}
					</div>

					<Hidden smDown>
						<div className="flex flex-1 justify-center">
							<Link to={adminPage ? '/admin' : '/'}>
								<div className={clsx('flex flex-shrink-0 items-center')}>
									<Logo />
								</div>
							</Link>
						</div>
					</Hidden>

					<div className="flex flex-1 justify-end">
						{profile ? (
							<UserMenu adminPage={adminPage} />
						) : (
							<Button className="h-64" href="/api/sso/login" role="button">
								<Icon>lock</Icon>

								<div className="mx-12">
									<Typography component="span" className="font-600 flex">
										{t('Login')}
									</Typography>
								</div>
							</Button>
						)}

						{/* <div className={classes.separator} />

						<LanguageSwitcher />

						<Hidden mdDown>
							<div className={classes.separator} />
						</Hidden> */}
					</div>
				</Toolbar>
			</AppBar>
		</ThemeProvider>
	);
}

export default React.memo(ToolbarLayoutAdmin);
