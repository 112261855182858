import * as Actions from 'app/store/actions';
import { PurgeStateAction } from './licenseGroups.types';

export type OrderType = 'DEMO' | 'NFR' | 'PRODUCTION';

export type Receipt = {
	token: string;
	status: 'SUCCESS';
	order: {
		subtotal: number;
		tax: number;
		total: number;
		type: OrderType;
		items: {
			[catalogId: number]: {
				name: string;
				quantity: number;
			};
		};
	};
};

export enum PublicId {
	DeviceApp = '84870BF9-5A03-4F0C-B128-1A112BA98E9D',
	FreeTrial = '8C14E200-6F4F-470D-9600-158B2B2720A5',
	NFR = '95D94048-D31C-44C8-A556-D3E37968D50E',
	StarterMonthly = '7510053C-007C-4739-B939-3D6DCAA894AF',
	StarterYearly = '13421508-5D29-4D7E-A81C-7E3650A029EA',
	StarterYearlyTerm = '8DD213B8-349D-42C0-8E20-122F8628117F',
	BusinessMonthly = '63C78DE7-EB09-4BB0-ADDF-93FEA8D9CF2F',
	BusinessYearly = 'A33092BF-C2ED-4382-8DE9-6CDA1BC8C42B',
	BusinessYearlyTerm = '2B5C72E7-58BD-4E41-8A0A-E00858E19780',
	EnterpriseMonthly = 'D78F6F17-3717-4528-9418-A963E52FCAF1',
	EnterpriseYearly = '8BB0F434-C4ED-4049-AAA6-8F473FCB7483',
	EnterpriseYearlyTerm = '361F9B6A-690D-4EE0-AC0D-792DB9912F40'
}

export type CatalogItem = {
	publicId: PublicId;
	name: string;
	showPrice: boolean;
	defaultPrice: number;
	priceRanges: [
		{
			amount: number;
			min: number;
			// sap_id: string;
		}
	];
	billing: {
		uid: string;
		// name: string;
		// duration: number;
		// months: boolean;
		// gracePeriod: number;
	};
};

export type MarketState = {
	currency: string;
	catalog: {
		[publicId in PublicId]: CatalogItem;
	};
};

type GetCatalogSuccessAction = {
	type: typeof Actions.GET_CATALOG_SUCCESS;
	payload: {
		currency: string;
		catalog: {
			[publicId in PublicId]: CatalogItem;
		};
	};
};

export type MarketActionTypes = GetCatalogSuccessAction | PurgeStateAction;
