import MomentUtils from '@date-io/moment';
// import FuseAuthorization from '@fuse/core/FuseAuthorization';
import FuseLayout from '@fuse/core/FuseLayout';
import FuseTheme from '@fuse/core/FuseTheme';
import history from '@history';
import { createGenerateClassName, jssPreset, StylesProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { create } from 'jss';
import jssExtend from 'jss-plugin-extend';
import rtl from 'jss-rtl';
import React from 'react';
import Provider from 'react-redux/es/components/Provider';
import { Router, Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryParamProvider } from 'use-query-params';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18n';
import AppContext from './AppContext';
// import { Auth } from './auth';
import routes from './fuse-configs/routesConfig';
import store, { persistor } from './store';
import PolicyEnforcerContextProvider from './components/PolicyEnforcer/PolicyEnforcerProvider';

const jss = create({
	...jssPreset(),
	plugins: [...jssPreset().plugins, jssExtend(), rtl()],
	insertionPoint: document.getElementById('jss-insertion-point')
});

const generateClassName = createGenerateClassName();

const App = () => {
	return (
		<AppContext.Provider
			value={{
				routes
			}}
		>
			<PolicyEnforcerContextProvider>
				<StylesProvider jss={jss} generateClassName={generateClassName}>
					<Provider store={store}>
						{/* I18nextProvider *shouldn't* be needed with React.Suspense is use but there seems to be some bug */}
						<I18nextProvider i18n={i18n}>
							<PersistGate persistor={persistor} loading={null}>
								<MuiPickersUtilsProvider utils={MomentUtils}>
									{/* <Auth> */}
									<Router history={history}>
										<QueryParamProvider ReactRouterRoute={Route}>
											{/* <FuseAuthorization> */}
											<FuseTheme>
												<FuseLayout />
											</FuseTheme>
											{/* </FuseAuthorization> */}
										</QueryParamProvider>
									</Router>
									{/* </Auth> */}
								</MuiPickersUtilsProvider>
							</PersistGate>
						</I18nextProvider>
					</Provider>
				</StylesProvider>
			</PolicyEnforcerContextProvider>
		</AppContext.Provider>
	);
};

export default App;
