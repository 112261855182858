import React from 'react';
import usePageTitle from 'app/hooks/usePageTitle';
import { useTranslation } from 'react-i18next';

function FuseSplashScreen() {
	const { t, ready } = useTranslation(undefined, { useSuspense: false });
	usePageTitle('Loading...');

	return (
		<div id="fuse-splash-screen">
			<div className="center">
				<div className="logo">
					<img
						width="128"
						src={`${process.env.PUBLIC_URL}/assets/images/logos/logo.svg`}
						alt={ready ? t('Personalize') : ''}
					/>
				</div>
				<div className="spinner-wrapper">
					<div className="spinner">
						<div className="inner">
							<div className="gap" />
							<div className="left">
								<div className="half-circle" />
							</div>
							<div className="right">
								<div className="half-circle" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default React.memo(FuseSplashScreen);
