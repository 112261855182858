import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const DeviceIcon = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<style type="text/css">{`
            .st0{fill:none;stroke:currentColor;stroke-width:1.9642;stroke-miterlimit:10;}
            .st1{fill:none;stroke:currentColor;stroke-width:1.9766;stroke-miterlimit:10;}
        `}</style>
		<line className="st0" x1="14" y1="8.7" x2="14" y2="22.8" />
		<path
			className="st1"
			d="M13.6,8.7h5.9c0.6,0,1-0.5,1-1V5.2c0-0.6-0.5-1-1-1h-5.9c-0.6,0-1,0.5-1,1v2.5C12.6,8.3,13.1,8.7,13.6,8.7z"
		/>
		<line className="st0" x1="4.1" y1="7.6" x2="12.4" y2="7.6" />
		<path
			className="st0"
			d="M19.5,22.9H4.8c-0.6,0-1-0.5-1-1V2.3c0-0.6,0.5-1,1-1h5.3v0.8l9.4-0.8c0.6,0,1,0.5,1,1V22
	C20.5,22.5,20.1,22.9,19.5,22.9z"
		/>
	</SvgIcon>
);

export default DeviceIcon;
