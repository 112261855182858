import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	root: {
		'& .logo-icon': {
			height: 48,
			transition: theme.transitions.create(['width', 'height'], {
				duration: theme.transitions.duration.shortest,
				easing: theme.transitions.easing.easeInOut
			})
		},
		'& .logo-badge': {
			transition: theme.transitions.create('opacity', {
				duration: theme.transitions.duration.shortest,
				easing: theme.transitions.easing.easeInOut
			})
		}
	}
}));

const Logo = ({ light = false }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<div className={clsx(classes.root, 'flex items-center')}>
			{light ? (
				<img
					className="logo-icon"
					src={`${process.env.PUBLIC_URL}/assets/images/logos/logo-text-white.svg`}
					alt={t('Personalize')}
				/>
			) : (
				<img
					className="logo-icon"
					src={`${process.env.PUBLIC_URL}/assets/images/logos/logo-text-blue.svg`}
					alt={t('Personalize')}
				/>
			)}
		</div>
	);
};

export default Logo;
