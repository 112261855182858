import i18n from 'i18n';
import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import { RootState } from 'app/store';
import { MpDevicesState, MpDevicesActionTypes } from 'app/store/types';
import * as Actions from '../actions';

const initialState: MpDevicesState = {
	list: undefined
};

const mpDevicesReducer = (state = initialState, action: MpDevicesActionTypes) => {
	switch (action.type) {
		case Actions.GET_MP_DEVICES_SUCCESS: {
			return {
				...state,
				list: action.payload.list.map(device => ({
					...device,
					name: `${i18n.t([`product line:${device.productLine}`, device.productLine])} ${device.name}`
				}))
			};
		}
		case Actions.LOGGED_OUT_USER:
		case Actions.PURGE_STATE:
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'mpDevices',
		storage: localForage,
		whitelist: [] // get list every site revisit
	},
	mpDevicesReducer
);

// Selectors
export const getMpDevices = ({ mpDevices }: RootState) => mpDevices.list;
