import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Axios from 'axios';
import { RootState } from 'app/store';
import _ from 'lodash';
import { AppThunk } from '..';
import { alert } from '../actions';
import { getSelectedLicenseGroupId } from '../reducers';
import {
	DepartmentUIInitialState,
	getDepartmentUiResponse,
	postDepartmentUiResponse
} from '../types/departmentUi.types';
import { PanelDataResponse } from '../types/panelEditor.types';

const initialState: DepartmentUIInitialState = {
	default: null,
	departmentUIs: {},
	selectedUI: null
};

const departmentUiSlice = createSlice({
	name: 'departmentUi',
	initialState,
	reducers: {
		setDepartmentUIs: (state, { payload }: PayloadAction<getDepartmentUiResponse['departmentUIs']>) => {
			state.departmentUIs = payload;
		},
		addDepartmentUI: (
			state,
			{ payload }: PayloadAction<{ key: string; departmentUi: getDepartmentUiResponse['departmentUIs'][1] }>
		) => {
			state.departmentUIs = {
				...state.departmentUIs,
				[payload.key]: payload.departmentUi
			};
		},
		setDefault: (state, { payload }: PayloadAction<string | null>) => {
			state.default = payload;
		},
		setSelectedUI: (state, { payload }: PayloadAction<string | null>) => {
			state.selectedUI = payload;
		},
		setSelectedUIName: (state, { payload }: PayloadAction<string>) => {
			if (!state.selectedUI) return;
			state.departmentUIs[state.selectedUI].name = payload;
			state.departmentUIs[state.selectedUI].lastModified = Date.now();
		},
		replaceSelectedUI: (state, { payload }: PayloadAction<PanelDataResponse>) => {
			if (state.selectedUI && state.departmentUIs[state.selectedUI]) {
				state.departmentUIs[state.selectedUI].panelData = payload;
			}
		},
		_deleteSelectedUi: state => {
			const { selectedUI, default: _d } = state;
			if (!selectedUI) return;
			if (selectedUI === _d) state.default = null;
			const newDUI = { ...state.departmentUIs };
			delete newDUI[selectedUI];
			state.selectedUI = null;
			state.departmentUIs = newDUI;
		}
	}
});

export default departmentUiSlice.reducer;
export const {
	setDefault,
	setDepartmentUIs,
	addDepartmentUI,
	setSelectedUI,
	setSelectedUIName,
	replaceSelectedUI,
	_deleteSelectedUi
} = departmentUiSlice.actions;

export const getDepartmentUi = (isAdmin: boolean): AppThunk => async (dispatch, getState) => {
	try {
		const state = getState();
		const licenseGroupId = getSelectedLicenseGroupId(state);
		const { data } = await Axios.get<any, { data: getDepartmentUiResponse }>(
			isAdmin ? `/api/license-groups/${licenseGroupId}/department-ui` : '/api/user/ui'
		);
		dispatch(setDefault(data.default));
		dispatch(setSelectedUI(data.default));
		dispatch(setDepartmentUIs(isAdmin ? data.departmentUIs : data.UIs));
	} catch (e) {
		console.error(e);
	}
};

export const createDepartmentUI = (name: string, isAdmin: boolean): AppThunk => async (dispatch, getState) => {
	try {
		const state = getState();
		const licenseGroupId = getSelectedLicenseGroupId(state);
		const { data } = await Axios.post<any, { data: postDepartmentUiResponse }>(
			isAdmin ? `/api/license-groups/${licenseGroupId}/department-ui` : '/api/user/ui',
			{
				name
			}
		);
		dispatch(
			addDepartmentUI({
				key: 'departmentUiId' in data ? data.departmentUiId : data.uiId,
				departmentUi: { name: data.name, panelData: data.panelData, lastModified: Date.now() }
			})
		);
	} catch (e) {
		console.error(e);
		const message = e.response?.data?.message;
		if (message === 'Too many license group department UIs' || message === 'Too many UIs') {
			dispatch(alert('Too many department UI', 'warning'));
		} else {
			dispatch(alert('something went wrong', 'warning'));
		}
	}
};

export const setDefaultUi = (id: string | null, isAdmin: boolean): AppThunk => async (dispatch, getState) => {
	try {
		const state = getState();
		const licenseGroupId = getSelectedLicenseGroupId(state);
		await Axios.post(
			isAdmin ? `/api/license-groups/${licenseGroupId}/department-ui/default` : '/api/user/ui/default',
			id === null ? { action: 'unset' } : { action: 'set', id }
		);
		dispatch(setDefault(id));
	} catch (e) {
		console.error(e);
	}
};

export const deleteSelectedUi = (isAdmin: boolean, callback?: (err: boolean) => void): AppThunk => async (
	dispatch,
	getState
) => {
	try {
		const state = getState();
		const { selectedUI } = state.departmentUI;
		const licenseGroupId = getSelectedLicenseGroupId(state);
		const { data } = await Axios.delete(
			isAdmin ? `/api/license-groups/${licenseGroupId}/department-ui/${selectedUI}` : `/api/user/ui/${selectedUI}`
		);
		if (data.status !== 'success') throw new Error('Data.status not success');
		if (callback) callback(false);
		dispatch(_deleteSelectedUi());
	} catch (e) {
		console.dir('ERROR\n', e);
		dispatch(alert('This UI does not exist', 'warning'));
		if (callback) callback(true);
	}
};

export const renameDepartmentUi = (name: string, isAdmin: boolean): AppThunk => async (dispatch, getState) => {
	try {
		const state = getState();
		const { selectedUI } = state.departmentUI;
		const licenseGroupId = getSelectedLicenseGroupId(state);
		await Axios.post(
			isAdmin
				? `/api/license-groups/${licenseGroupId}/department-ui/${selectedUI}/rename`
				: `/api/user/ui/${selectedUI}/rename`,
			{
				name
			}
		);
		dispatch(setSelectedUIName(name));
	} catch (e) {
		console.error(e);
	}
};

export const getDepartmentUiData = ({ departmentUI }: RootState) =>
	departmentUI.departmentUIs
		? Object.values(
				_.mapValues(departmentUI.departmentUIs, (value, key) => {
					return {
						id: key,
						name: value.name
					};
				})
		  )
		: [];
