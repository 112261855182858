import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { setNavigation } from 'app/store/actions';

type NavObject = {
	id: string;
	translate: string;
	type: string;
	icon: string;
	url?: string;
};

export default (
	props: {
		matcher: RegExp;
		navigationObject: NavObject[];
	}[]
) => {
	const history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {
		const onChange = (pathname: string) => {
			const match = props.find(({ matcher }) => matcher.test(pathname));
			if (match) dispatch(setNavigation(match.navigationObject));
		};
		const unlisten = history.listen(({ pathname }) => onChange(pathname));

		onChange(window.location.pathname);

		return () => unlisten();
	}, [history, props, dispatch]);
};
