import i18n from 'i18n';
import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import _ from '@lodash';
import { RootState } from 'app/store';
import { DevicesState, DevicesActionTypes } from 'app/store/types';
import * as Actions from '../actions';

const initialState: DevicesState = {};

const devicesReducer = (state = initialState, action: DevicesActionTypes) => {
	switch (action.type) {
		case Actions.GET_LICENSE_GROUP_DATA_SUCCESS: {
			const {
				licenseGroupId,
				data: { devices }
			} = action.payload;

			return {
				...state,
				[licenseGroupId]: {
					byId: _.mapValues(devices, device => ({
						...device,
						name: `${i18n.t([
							`product line:${device.productLine}`,
							device.productLine ?? 'product line:unknown'
						])} ${device.name}`
					}))
				}
			};
		}
		case Actions.LOGGED_OUT_USER:
		case Actions.PURGE_STATE:
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'devices',
		storage: localForage
	},
	devicesReducer
);

// Selectors
export const getDevices = ({ app: { selectedLicenseGroupId }, devices }: RootState) =>
	Object.values(devices[selectedLicenseGroupId].byId);
