// import i18next from 'i18next';
// import en from './navigation-i18n/en';

// i18next.addResourceBundle('en', 'navigation', en);

export const adminNavigationConfig = [
	{
		id: 'dashboard',
		translate: 'Dashboard',
		type: 'item',
		icon: 'donut_large',
		url: '/admin/dashboard'
	},
	{
		id: 'users',
		translate: 'Users',
		type: 'item',
		icon: 'group',
		url: '/admin/users'
	},
	{
		id: 'devices',
		translate: 'Devices',
		type: 'item',
		icon: 'device',
		url: '/admin/devices'
	},
	{
		id: 'panel-editor',
		translate: 'Group Default',
		type: 'item',
		icon: 'view_quilt',
		url: '/admin/group-default'
	},
	{
		id: 'reports',
		translate: 'Reports',
		type: 'item',
		icon: 'assessment',
		url: '/admin/reports'
	},
	{
		id: 'settings',
		translate: 'Settings',
		type: 'item',
		icon: 'settings',
		url: '/admin/settings'
	},
	{
		id: 'subscription-details',
		translate: 'Subscription Details',
		type: 'group',
		icon: 'receipt',
		url: '/admin/modify-subscription',
		children: [
			{
				id: 'modify-subscription',
				translate: 'Modify Subscription',
				type: 'item',
				icon: 'receipt',
				url: '/admin/modify-subscription'
			},
			{
				id: 'subscription-information',
				translate: 'Subscription Information',
				type: 'item',
				icon: 'table_chart',
				url: '/admin/subscription-information'
			}
		]
	}
];

export const userNavigationConfig = [
	{
		id: 'panel-editor',
		translate: 'Panel Editor',
		type: 'item',
		icon: 'view_quilt',
		url: '/user/panel-editor'
	},
	{
		id: 'user-settings',
		translate: 'Settings',
		type: 'item',
		icon: 'settings',
		url: '/user/settings'
	}
];

const navigationConfig = adminNavigationConfig;

export default navigationConfig;
