import axios from 'app/client';
import _ from '@lodash';
import { responseErrors } from 'app/utils/helpers';
import { getSelectedLicenseGroupId } from 'app/store/reducers';
import { AppThunk } from 'app/store';
import * as appActions from './app.actions';
import * as licenseGroupsActions from './licenseGroups.actions';
import * as devicesActions from './devices.actions';

export const addBlacklistDevices = (serials: string): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	try {
		await dispatch(devicesActions.removeDevices(serials));
		const responses = await Promise.all(
			_.chunk(serials, 25).map(chunk =>
				axios.patch(`/api/license-groups/${licenseGroupId}/device-blacklist`, { add: chunk })
			)
		);
		if (responseErrors(responses).length) {
			dispatch(appActions.alert('failed to blacklist some devices', 'warning'));
		} else {
			dispatch(appActions.alert('device blacklist updated', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const removeFromBlacklistDevices = (serials: string): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	try {
		const responses = await Promise.all(
			_.chunk(serials, 25).map(chunk =>
				axios.patch(`/api/license-groups/${licenseGroupId}/device-blacklist`, { delete: chunk })
			)
		);
		if (responseErrors(responses).length) {
			dispatch(appActions.alert('failed to remove some devices from blacklist', 'warning'));
		} else {
			dispatch(appActions.alert('devices removed from blacklist', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};
