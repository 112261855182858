import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'app/client';
import Color from 'color';
import { MD5 } from 'object-hash';
import { flatten, omit, uniqueId } from 'lodash';
import { themes, TStartUpApp } from 'app/main/panel-editor/constants';
import Axios from 'axios';
import AlertDialog from 'app/components/AlertDialog';
import Panel from 'app/main/panel-editor/Panel';
import { brokenImageUrl, getIconPathFromId } from 'app/main/panel-editor/components/Tile';
import getRandomInt from 'app/main/panel-editor/components/getRandom';
import {
	InitialState,
	PanelDataResponse,
	NativeDeviceFunctionsResponse,
	AppsResponse,
	PanelWidget,
	Widget,
	PanelWidgetUnplaced
} from '../types/panelEditor.types';
import { alert } from '../actions';
import { AppThunk, RootState } from '..';
import { showMessage } from '../actions/fuse/message.actions';
import { getSelectedLicenseGroupId } from '../reducers';
import { useSelector } from 'app/modules/react-redux';
import { AppState } from '../types';

export const initialState: InitialState = {
	loading: false,
	page: 0,
	scaleX: 0.7625,
	enableBackgroundImage: true,
	initialResponses: {},
	currentHash: '',
	startHash: '',
	loadedFromDefault: false,
	unsavedChangesWarningTimeShown: Date.now()
};

const panelEditorSlice = createSlice({
	initialState: initialState as InitialState,
	name: 'panel-editor',
	reducers: {
		setInitialState(
			state,
			{
				payload
			}: PayloadAction<{
				initialResponses: InitialState['initialResponses'];
				appearance: InitialState['appearance'];
				layoutPages: InitialState['layoutPages'];
				savedThemeName: InitialState['savedThemeName'];
				panelApps: InitialState['panelApps'];
				panelFunctions: InitialState['panelFunctions'];
				language?: string;
				loadedFromDefault?: boolean;
				cloudFrontUrl?: string;
				startUp: InitialState['startUp'];
			}>
		) {
			const newState = {
				...initialState,
				page: state.page,
				initialResponses: payload.initialResponses,
				appearance: payload.appearance,
				layoutPages: payload.layoutPages,
				savedThemeName: payload.savedThemeName,
				panelApps: payload.panelApps,
				panelFunctions: payload.panelFunctions,
				language: payload.language || 'English',
				loadedFromDefault: payload.loadedFromDefault || false,
				cloudFrontUrl: payload.cloudFrontUrl,
				startUp: payload.startUp
			};
			newState.currentHash = hashState(newState);
			newState.startHash = newState.currentHash;
			return newState;
		},
		setInitialResponsePanel(state, { payload }: PayloadAction<PanelDataResponse>) {
			state.initialResponses.panelData = payload;
		},
		setLayout(state, { payload }: PayloadAction<PanelWidget[][]>) {
			state.layoutPages = payload;
			state.currentHash = hashState(state as InitialState);
		},
		setLoading(state, { payload }: PayloadAction<boolean>) {
			state.loading = payload;
		},
		setPage(state, { payload }: PayloadAction<number>) {
			state.page = payload;
		},
		setHashes(state, { payload }: PayloadAction<{ startHash: string; currentHash: string }>) {
			state.currentHash = payload.currentHash;
			state.startHash = payload.startHash;
		},
		setImage(state, { payload }: PayloadAction<InitialState['replacementImage']>) {
			state.replacementImage = payload;
			state.nullOutBackground = undefined;
			state.currentHash = hashState(state as InitialState);
		},
		setNullOutImage(state, { payload }: PayloadAction<undefined | boolean>) {
			state.replacementImage = undefined;
			state.nullOutBackground = payload;
			state.currentHash = hashState(state as InitialState);
		},
		setAppearance(state, { payload }: PayloadAction<InitialState['appearance']>) {
			if (payload) {
				const payloadWithBg = {
					...payload,
					paletteName: getPaletteIdFromAppearance(payload)
				};
				if (state.appearance?.backgroundImage && !payload.backgroundImage) {
					payloadWithBg.backgroundImage = state.appearance?.backgroundImage;
				}
				state.appearance = { ...payloadWithBg };
			} else {
				state.appearance = undefined;
			}
			state.previewAppearance = undefined;
			state.currentHash = hashState(state as InitialState);
		},
		setSavedThemeName(state, { payload }: PayloadAction<InitialState['savedThemeName']>) {
			state.savedThemeName = payload;
		},
		setPreviewAppearance(state, { payload }: PayloadAction<InitialState['previewAppearance']>) {
			state.previewAppearance = payload;
		},
		setPanelEditorLanguage(state, { payload }: PayloadAction<string>) {
			state.language = payload;
			state.currentHash = hashState(state as InitialState);
		},
		resetPanelEditorSlice() {
			return initialState;
		},
		setLoadedFromDefault(state, { payload }: PayloadAction<boolean>) {
			state.loadedFromDefault = payload;
		},
		setCloudfrontUrl(state, { payload }: PayloadAction<string>) {
			state.cloudFrontUrl = payload;
		},
		setUnsavedChangesWarningTimeShown(state) {
			state.unsavedChangesWarningTimeShown = Date.now();
		},
		setStartUp(state, { payload }: PayloadAction<InitialState['startUp']>) {
			state.startUp = payload;
			state.currentHash = hashState(state as InitialState);
		}
	}
});

export const {
	setInitialState,
	setLayout,
	setLoading,
	setPage,
	setHashes,
	setInitialResponsePanel,
	setImage,
	setAppearance,
	setPreviewAppearance,
	setPanelEditorLanguage,
	setNullOutImage,
	resetPanelEditorSlice,
	setLoadedFromDefault,
	setSavedThemeName,
	setCloudfrontUrl,
	setUnsavedChangesWarningTimeShown,
	setStartUp
} = panelEditorSlice.actions;
export default panelEditorSlice.reducer;

const hashState = (state: InitialState) => {
	return MD5({
		pages: state.layoutPages,
		appearance: state.appearance,
		backgroundImage: state.replacementImage?.hash,
		language: state.language,
		nullOutBackground: state.nullOutBackground,
		startUp: state.startUp
	});
};

const colorToRGBA = (color: string, alpha: number) => {
	const c = Color(color);

	return `rgba(${c.red()}, ${c.green()}, ${c.blue()}, ${alpha})`;
};

export const getPaletteIdFromAppearance = (appearance: Partial<InitialState['appearance']>): string => {
	if (!appearance) return themes[0].name;

	const keys = ['backgroundColor', 'fontColor', 'iconBackground', 'textBackground'];
	const theme = themes.find(item => {
		// eslint-disable-next-line
		for (const key of keys) {
			if (
				// @ts-ignore
				Color(item[key])
					.rgb()
					.toString() !==
				// @ts-ignore
				Color(appearance[key])
					.rgb()
					.toString()
			)
				return false;
		}
		return true;
	});
	return theme ? theme.name : 'custom';
};

const parsePanelData = (
	panelData: PanelDataResponse,
	existingFunctions: PanelWidgetUnplaced[]
): {
	appearance: InitialState['appearance'];
	layoutPages: InitialState['layoutPages'];
	savedThemeName: InitialState['savedThemeName'];
} => {
	const appearance = {
		backgroundImage: panelData.backgroundImageUrl,
		backgroundColor: panelData.backgroundColor,
		fontColor: panelData.fontColor,
		iconBackground: colorToRGBA(
			panelData.widgetAppearance.iconBackgroundColor,
			panelData.widgetAppearance.iconBackgroundAlpha
		),
		textBackground: colorToRGBA(
			panelData.widgetAppearance.textBackgroundColor,
			panelData.widgetAppearance.textBackgroundAlpha
		)
	};
	const themeName = getPaletteIdFromAppearance(appearance);
	return {
		appearance: {
			...appearance,
			paletteName: themeName
		},
		savedThemeName: themeName,
		layoutPages: panelData.layoutPages.map((page, pageNumber) =>
			page.map(
				(widget): PanelWidget => ({
					key: `${getRandomInt(6)}`,
					id: widget.id,
					page: pageNumber,
					icon: widget['||app||']
						? widget['||app||'].iconUrl || brokenImageUrl
						: getIconPathFromId(widget.id)[0],
					name: widget['||app||']
						? widget['||app||'].name
						: existingFunctions.find(item => item.id === widget.id)?.name || 'Unknown',
					position: {
						...widget.position
					},
					size: {
						...widget.size
					},
					raw: {
						...widget
					}
				})
			)
		)
	};
};

const parseNativeFunctionToPanelFunctions = (
	functions: NativeDeviceFunctionsResponse,
	existingArray: PanelWidgetUnplaced[] = []
): PanelWidgetUnplaced[] => {
	return [
		...existingArray,
		...Object.keys(functions).map(
			(key): PanelWidgetUnplaced => {
				const f = functions[key];
				return {
					icon: getIconPathFromId(key)[0],
					id: key,
					name: f.name,
					size: {
						height: 1,
						width: 1
					},
					raw: {
						id: key,
						page: -1,
						position: {
							x: -1,
							y: -1
						},
						size: {
							height: 1,
							width: 1
						}
					}
				};
			}
		)
	];
};

const formatId = (type: string, id: string, registrationNumber: number) => {
	if (type !== 'OAP' || /\d+_.+/.test(id)) return id;
	return `${registrationNumber}_${id}`;
};

const parseAppsToPanelFunctions = (
	apps: AppsResponse,
	existingArray: PanelWidgetUnplaced[] = []
): PanelWidgetUnplaced[] => {
	return [
		...existingArray,
		...flatten(
			apps.map(({ app, iconUrl }) =>
				app.specifiableSizes.map(
					({ height, width }): PanelWidgetUnplaced => ({
						icon: iconUrl || brokenImageUrl,
						id: `${formatId(app.type, app.id, app.registrationNumber)}_${app.name}_${width}x${height}`,
						name: app.name,
						size: {
							height,
							width
						},
						raw: {
							id: formatId(app.type, app.id, app.registrationNumber),
							page: -1,
							position: {
								x: -1,
								y: -1
							},
							size: {
								height,
								width
							},
							'||app||': {
								...app,
								iconUrl
							}
						}
					})
				)
			)
		)
	];
};

const getSetInitialStateParams = (
	panelData: PanelDataResponse,
	functions: NativeDeviceFunctionsResponse,
	apps: AppsResponse,
	isLoadedFromDefault: boolean,
	cloudFrontUrl?: string
): Parameters<typeof setInitialState>[0] => {
	const panelFunctions = parseNativeFunctionToPanelFunctions(functions);
	const panelApps = parseAppsToPanelFunctions(apps).filter(
		(value, index, self) => self.findIndex(item => item.id === value.id) === index
	);
	const { appearance, layoutPages, savedThemeName } = parsePanelData(panelData, panelFunctions);

	return {
		appearance,
		layoutPages,
		savedThemeName,
		panelFunctions,
		panelApps,
		language: panelData.language,
		initialResponses: {
			panelData,
			apps,
			nativeDeviceFunctions: functions
		},
		loadedFromDefault: isLoadedFromDefault,
		cloudFrontUrl,
		startUp: panelData.startUp
	};
};

const reduxStateToRequest = (state: InitialState): PanelDataResponse => ({
	startUp: state.startUp,
	backgroundColor: Color(state.appearance?.backgroundColor || '#000').hex(),
	fontColor: Color(state.appearance?.fontColor || '#000').hex(),
	widgetAppearance: {
		iconBackgroundAlpha: Color(state.appearance?.iconBackground || '#000').alpha(),
		iconBackgroundColor: Color(state.appearance?.iconBackground || '#000').hex(),
		textBackgroundAlpha: Color(state.appearance?.textBackground || '#000').alpha(),
		textBackgroundColor: Color(state.appearance?.textBackground || '#000').hex()
	},
	language: state.language,
	layoutPages: (state.layoutPages || []).map((page, pageNumber) =>
		page.map(
			(tile): Widget => {
				return {
					...tile.raw,
					position: {
						...tile.position
					},
					page: pageNumber + 1
				};
			}
		)
	)
});

export const savePanelData = (
	callback: (panelData?: PanelDataResponse) => void,
	savePath: string,
	backgroundUploadPath: string
): AppThunk => async (dispatch, getState) => {
	try {
		const editorState = getState().panelEditor;
		const parsedState = reduxStateToRequest(editorState);

		const request: any = {
			panelData: parsedState
		};

		if (editorState.replacementImage) {
			request.background = {
				hash: `sha256_${editorState.replacementImage.hash}`
			};
		}

		if (editorState.loadedFromDefault && editorState.appearance?.backgroundImage) {
			const link = editorState.appearance.backgroundImage;
			request.background = {
				hash: `sha256_${link.split('sha256_')[1].split('_')[0]}`
			};
		}

		if (editorState.nullOutBackground) {
			request.background = 'unset';
		}

		const { data } = await axios.patch<
			any,
			{
				data: {
					operations: { type: string }[];
				};
			}
		>(savePath, request);
		const hasToUpload = data.operations.find(item => item.type === 'upload_background');
		if (editorState.replacementImage && hasToUpload) {
			await Axios.post(
				`${backgroundUploadPath}${editorState.replacementImage.hash}`,
				editorState.replacementImage.buffer,
				{
					headers: {
						'Content-Type': editorState.replacementImage.type
					}
				}
			);
		}
		const hash = hashState(editorState);
		dispatch(setHashes({ currentHash: hash, startHash: hash }));
		parsedState.backgroundImageUrl =
			editorState.replacementImage?.dataURL || editorState.appearance?.backgroundImage;
		dispatch(setInitialResponsePanel(parsedState));
		dispatch(setLoadedFromDefault(false));
		dispatch(setSavedThemeName(editorState.appearance?.paletteName));
		callback(
			request.background === 'unset' ? { ...request.panelData, backgroundImageUrl: null } : request.panelData
		);
		dispatch(alert('Your changes have been saved', 'success'));
	} catch (e) {
		console.error(e);
		if (e.response.status === 413) {
			dispatch(alert('Your selected background file size is too big.'));
		} else {
			dispatch(alert('We failed to save your changes', 'error'));
		}
		callback();
	}
};

export const loadDefaultLayout = (callback: () => void): AppThunk => async (dispatch, getState) => {
	try {
		const { panelApps, panelFunctions } = getState().panelEditor;
		if (!panelApps) {
			dispatch(alert('Cannot reset to default before panel is loaded', 'error'));
			return;
		}
		const { data } = await axios.get<any, { data: PanelDataResponse }>('/api/user/panel-data/default');
		const { layoutPages, appearance } = parsePanelData(data, [...panelApps, ...panelFunctions]);
		if (!layoutPages) throw Error('Unexpected error (2)');
		dispatch(setLayout(layoutPages));
		dispatch(setAppearance(appearance));

		if (appearance?.backgroundImage && data.backgroundImageHash) {
			dispatch(
				setImage({
					buffer: new Uint8Array(),
					type: '',
					dataURL: appearance.backgroundImage,
					hash: data.backgroundImageHash.split('_')[1]
				})
			);
		} else if (getState().panelEditor.appearance?.backgroundImage) {
			dispatch(setNullOutImage(true));
		}

		if (data.language) {
			dispatch(setPanelEditorLanguage(data.language));
		}
		dispatch(alert('Your panel has been reset to its default layout.', 'success'));
		callback();
	} catch (e) {
		console.error(e);
		callback();
		dispatch(alert('There was an issue loading the default layout', 'error'));
	}
};

export const discardPanelChanges = (): AppThunk => async (dispatch, getState) => {
	const { initialResponses, loadedFromDefault, cloudFrontUrl } = getState().panelEditor;
	if (!initialResponses.nativeDeviceFunctions || !initialResponses.panelData || !initialResponses.apps) {
		dispatch(alert('Cannot discard changes before panel is loaded', 'error'));
		return;
	}
	dispatch(
		setInitialState(
			getSetInitialStateParams(
				initialResponses.panelData,
				initialResponses.nativeDeviceFunctions,
				initialResponses.apps,
				loadedFromDefault,
				cloudFrontUrl
			)
		)
	);
	dispatch(alert('Your changes have been discarded', 'success'));
};

export const initializePanelState = (params: {
	panelUrl: string;
	extractor?: (data: any) => any;
	isAdmin: boolean;
}): AppThunk => async (dispatch, getState) => {
	const isAdmin = params.isAdmin || false;
	const { extractor, panelUrl } = params;
	try {
		const licenseGroupId = isAdmin
			? getSelectedLicenseGroupId(getState()) || Object.keys(getState().licenseGroups.byId)[0]
			: getState().profile.licensedBy?.id;

		const response = await Promise.allSettled([
			axios.get<any, { data: PanelDataResponse }>(panelUrl),
			axios.get<any, { data: NativeDeviceFunctionsResponse }>('/api/native-device-functions'),
			axios.get(`/api/license-groups/${licenseGroupId}/apps`)
		]);

		let [panelData] = response;
		const [, nativeFunctions, apps] = response;
		let isLoadedFromDefault = false;
		if (panelData.status === 'rejected') {
			const value = await axios.get<any, { data: PanelDataResponse }>('/api/user/panel-data/default');
			panelData = {
				status: 'fulfilled',
				value
			};
			isLoadedFromDefault = true;
		} else if (extractor) {
			panelData.value.data = extractor(panelData.value.data);
		}
		if (nativeFunctions.status === 'rejected') throw Error('Failed to get native MFP functions');
		if (apps.status === 'rejected' && licenseGroupId) throw Error('Failed to get license group apps');
		let cloudFrontUrl;
		if ('value' in apps) {
			const cloudFront = apps.value.data.find((item: any) => item.iconUrl);
			if (cloudFront) cloudFrontUrl = new URL(cloudFront.iconUrl).origin;
		}
		dispatch(
			setInitialState(
				getSetInitialStateParams(
					panelData.value.data,
					nativeFunctions.value.data,
					apps.status === 'rejected' ? [] : apps.value.data,
					isLoadedFromDefault,
					cloudFrontUrl
				)
			)
		);
	} catch (e) {
		console.error(e);
		dispatch(alert('Failed to load your panel', 'error'));
	}
};

export const IWSOrOAPSelector = (state: RootState): TStartUpApp[] | undefined =>
	state.panelEditor.panelApps
		?.filter(app => {
			const type = app.raw['||app||']?.type;
			if (
				!type ||
				!app.raw['||app||']?.id ||
				!app.raw['||app||']?.type ||
				!app.raw['||app||']?.number ||
				!app.raw['||app||']?.url
			)
				return false;
			return ['IWS', 'OAP'].includes(type);
		})
		.map(app => ({
			type: 'OapAppNo',
			value: {
				value: 'OapAppNo',
				app: app.raw['||app||']
			},
			key: app.id,
			translateKey: app.name
		}));

export const getPlacedAppIds = (state: RootState): string[] => {
	return flatten(state.panelEditor.layoutPages?.map(page => page.map(widget => widget.id)));
};
