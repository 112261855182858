import React from 'react';
import { Redirect } from 'react-router-dom';
import FuseUtils from '@fuse/utils';
import WelcomePageConfig from 'app/main/welcome/WelcomePageConfig';
import PricingPageConfig from 'app/main/pricing/PricingPageConfig';
import MaintenancePageConfig from 'app/main/maintenance/MaintenancePageConfig';
import ComingSoonPageConfig from 'app/main/coming-soon/ComingSoonPageConfig';
import ConfirmationPageConfig from 'app/main/confirmation/ConfirmationPageConfig';
import ReceiptPageConfig from 'app/main/receipt/ReceiptPageConfig';
import Error500PageConfig from 'app/main/error500/Error500PageConfig';
import LegalPageConfig from 'app/main/legal/LegalPageConfig';
import DashboardPageConfig from 'app/main/dashboard/DashboardPageConfig';
import UsersPageConfig from 'app/main/users/UsersPageConfig';
import DevicesPageConfig from 'app/main/devices/DevicesPageConfig';
import SettingsPageConfig from 'app/main/settings/SettingsPageConfig';
import SubscriptionDetailsPageConfig from 'app/main/subscription-details/SubscriptionDetailsPageConfig';
import PanelEditorConfig from 'app/main/panel-editor/PanelEditorPageConfig';
import PanelEditorAdminConfig from 'app/main/panel-editor/PanelEditorAdminConfig';
import SSORouterConfig from 'app/main/SSORouter/SSORouterConfig';
// import LoginPageConfig from 'app/main/login/LoginPageConfig';
import ReportsPageConfig from 'app/main/reports/ReportsPageConfig';
import UserSettingsPageConfig from 'app/main/user-settings/UserSettingsPageConfig';

const routeConfigs = [
	// Store
	WelcomePageConfig,
	PricingPageConfig,
	MaintenancePageConfig,
	ComingSoonPageConfig,
	ConfirmationPageConfig,
	ReceiptPageConfig,
	Error500PageConfig,
	LegalPageConfig,
	// LoginPageConfig,
	// Admin
	DashboardPageConfig,
	UsersPageConfig,
	DevicesPageConfig,
	SettingsPageConfig,
	SubscriptionDetailsPageConfig,
	PanelEditorConfig,
	PanelEditorAdminConfig,
	SSORouterConfig,
	ReportsPageConfig,
	UserSettingsPageConfig
];

const routes = [
	...FuseUtils.generateRoutesFromConfigs(routeConfigs),
	{
		path: '/user',
		exact: true,
		component: () => <Redirect to="/user/panel-editor" />
	},
	{
		path: '/admin',
		exact: true,
		component: () => <Redirect to="/admin/dashboard" />
	},
	{
		component: React.lazy(() => import('app/main/error404/Error404Page'))
	}
];

export default routes;
