import React, { useRef, useEffect, useCallback } from 'react';
import useImage from 'use-image';
import Konva from 'konva';
import { throttle } from 'lodash';
import { KonvaEventObject } from 'konva/types/Node';
import { InitialState, PanelWidget } from 'app/store/types/panelEditor.types';
import isInvalidPlacement from './isInvalidPlacement';
import { PlacementTile } from './ExternalItem';
import RawCanvasTile from './RawTile';
import { startUpApps } from '../constants';

const initCache = {
	lastX: -1,
	lastY: -1
};
let cache = {
	...initCache
};

interface TileProps {
	cloudfrontUrl?: string;
	layout: PanelWidget;
	selectedItem: PlacementTile | null;
	entireLayout: PanelWidget[];
	blockSize: number;
	canvasRef: React.MutableRefObject<HTMLDivElement | null>;
	setSelected: (layout: PlacementTile | null) => any;
	onDrop: (layout: PlacementTile) => any;
	appearance?: InitialState['appearance'];
	previewAppearance?: InitialState['previewAppearance'];
	remove: () => void;
	scaleX: number;
	translateKeys: { [key: string]: string };
	startUp: InitialState['startUp'];
}

export const getIconPathFromId = (appId?: string): [string, boolean] => {
	const mappings: { [key: string]: string | undefined } = {
		w00000001: 'copy.png',
		w00000002: 'scantoemail.png',
		w00000003: 'scantobox.png',
		w00000004: 'scantopc.png',
		w00000005: 'internetfax.png',
		w00000006: 'ipaddressfax.png',
		w00000007: 'fax.png',
		w00000016: 'ipfax.png',
		w00000017: 'copy.png',
		w00000101: 'browser.png',
		w00000102: 'marketplace.png',
		w00000201: 'addressbook.png',
		w00000202: 'externalmemory.png',
		w00000203: 'classicstyle.png',
		w00000204: 'copy_full.png',
		w00000205: 'scan_full.png',
		w00000206: 'fax_full.png',
		w00000207: 'Box.png',
		w00000208: 'ubiquitous_print.png',
		w00000301: 'cardcopy.png',
		w00000302: 'papersettings.png',
		w00000303: 'cardcopy.png',
		w00000304: 'scan-to-me.png',
		w00000401: 'app.png'
	};

	return [
		`${process.env.PUBLIC_URL}/assets/images/app-icons/${
			appId && mappings[appId] ? mappings[appId] : 'broken_image.svg'
		}`,
		!!(appId && mappings[appId])
	];
};

export const brokenImageUrl = `${process.env.PUBLIC_URL}/assets/images/app-icons/broken_image.svg`;

const Tile: React.FC<TileProps> = props => {
	// const { appearance } = useSelector((state: RootState) => state.panelEditor);
	const {
		layout,
		entireLayout,
		selectedItem,
		blockSize,
		setSelected,
		canvasRef,
		onDrop,
		remove,
		appearance,
		previewAppearance,
		scaleX,
		translateKeys,
		cloudfrontUrl,
		startUp
	} = props;

	const w = layout.size.width * blockSize * scaleX + layout.size.width - 1 * (blockSize * 0.0571);
	const h = layout.size.height * blockSize + (layout.size.height - 1) * (blockSize * 0.0571);

	// Default to a brokenImage if icon is not provided
	const [appIcon, isBrokenIcon] = getIconPathFromId(layout.id);
	const [image, imageState] = useImage(
		layout.icon
			? layout.icon
			: layout.raw['||app||']?.iconId && cloudfrontUrl
			? `${cloudfrontUrl}/${layout.raw['||app||'].iconId}`
			: appIcon
	);

	const groupRef = useRef<Konva.Group>(null);

	/*
    getGroupX and getGroupY is only necessary to handle the spacing
    between tiles because they do not share a border.
  */
	const getGroupX = useCallback((x: number) => x * (blockSize * scaleX), [blockSize, scaleX]);
	const getGroupY = useCallback((y: number) => y * blockSize + (y + 1) * 10 - 10, [blockSize]);

	// Set opacity to 0,5 and set the zIndex to make sure it says on top
	const onDragStart = (e: KonvaEventObject<DragEvent>) => {
		e.target.to({
			opacity: 0.5,
			duration: 0.2
		});
		e.target.setZIndex(1);
		setSelected(layout);
	};

	// Handling moving the tile inside of a canvas
	const onDragMove = (e: KonvaEventObject<DragEvent>) => {
		if (!canvasRef.current) {
			console.error('onDragEnd called before canvasRef is not null');
			return;
		}
		if (!e.currentTarget.parent) {
			console.log('onDragEnd e.currentTarget expected a parent');
			return;
		}

		/*
      get placement info at attach it to the layout
      this object holds info on items such as intersections
      out of bounds placements and a few other useful 
      data sets. See isInvalidPlacement.ts for more info
    */
		const placement = isInvalidPlacement(layout, entireLayout, canvasRef.current, e.evt.clientX, e.evt.clientY);

		canvasRef.current.style.cursor = placement.invalidPlacement ? 'no-drop' : 'move';

		// Don't update anything if cache is the same as placement
		if (cache.lastX === placement.x && cache.lastY === placement.y && !placement.outOfBounds) {
			return;
		}
		cache.lastX = placement.x;
		cache.lastY = placement.y;

		setSelected({
			...layout,
			position: { x: placement.x, y: placement.y },
			placement,
			draggedOut: placement.outOfBounds
		});
	};

	const onDragMoveThrottled = throttle(onDragMove, 100);

	const onDragEnd = (e: KonvaEventObject<DragEvent>) => {
		/*
      If the placement is invalid we return the tile to
      its original location
    */
		if (!groupRef.current) return;
		if (canvasRef.current) canvasRef.current.style.cursor = 'inherit';
		if (!selectedItem || selectedItem.placement?.invalidPlacement) {
			groupRef.current.to({
				opacity: 1,
				x: getGroupX(layout.position.x),
				y: getGroupY(layout.position.y)
			});
			setSelected(null);
			return;
		}

		onDrop(selectedItem);
		setSelected(null);
		groupRef.current.to({
			opacity: 1,
			x: getGroupX(selectedItem.position.x),
			y: getGroupY(selectedItem.position.y)
		});
	};

	/**
	 * This acts like a "snap" mechanism that snaps to a tile
	 * location after the layout,x,w has been updated
	 */
	useEffect(() => {
		if (!groupRef.current) return;
		groupRef.current.to({
			duration: 0.3,
			opacity: 1,
			x: getGroupX(layout.position.x),
			y: getGroupY(layout.position.y)
		});
	}, [layout.position.x, layout.position.y, blockSize, w, h, getGroupX, getGroupY]);

	/**
	 * This resets cache on init
	 */
	useEffect(() => {
		cache = {
			...initCache
		};
	}, []);

	const onMouseOver = useCallback(
		throttle(() => {
			if (canvasRef.current) canvasRef.current.style.cursor = 'grab';
		}, 100),
		[canvasRef]
	);

	const onMouseOverX = useCallback(
		throttle(() => {
			if (canvasRef.current) canvasRef.current.style.cursor = 'pointer';
		}, 100),
		[canvasRef]
	);

	let hasHeart = false;
	if (startUp) {
		if (typeof startUp === 'string') {
			const startUpApp = startUpApps.find(item => item.value === startUp);
			if (startUpApp && 'id' in startUpApp && startUpApp.id === layout.id) hasHeart = true;
		} else {
			const layoutId = `${layout.raw['||app||']?.id}_${layout.raw['||app||']?.name || ''}_${layout.raw['||app||']
				?.registrationNumber || ''}`;

			const startUpId = `${startUp.app?.id || ''}_${startUp.app?.name || ''}_${startUp.app?.registrationNumber}`;

			if (layoutId === startUpId) hasHeart = true;
		}
	}

	return (
		<RawCanvasTile
			groupProps={{
				onDragStart,
				onDragEnd,
				onMouseOver,
				draggable: true,
				onDragMove: onDragMoveThrottled,
				onFocus: onMouseOver,
				onMouseLeave: () => {
					if (canvasRef.current) canvasRef.current.style.cursor = 'initial';
				}
			}}
			onMouseOverX={onMouseOverX}
			appearance={previewAppearance || appearance}
			blockSize={blockSize}
			h={h}
			w={w}
			y={layout.position.y}
			x={layout.position.x}
			img={image}
			imgState={imageState}
			brokenImg={false}
			name={translateKeys[layout.name] || layout.name}
			scaleX={scaleX}
			onClickRemove={remove}
			ref={groupRef}
			hasHeart={hasHeart}
		/>
	);
};

export default Tile;
