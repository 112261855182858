import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	navbar: {
		display: 'flex',
		overflow: 'hidden',
		height: 64,
		minHeight: 64,
		alignItems: 'center',
		boxShadow: theme.shadows[3],
		backgroundColor: theme.palette.error.dark,
		zIndex: 6
	},
	text: {
		color: theme.palette.getContrastText(theme.palette.error.dark)
	}
}));

const ExpiredLicenseBanner = () => {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<Paper className={clsx(classes.navbar, 'w-full')} square>
			<div className="flex flex-auto items-center w-full h-full container px-16 lg:px-24">
				<Typography className={clsx(classes.text, 'w-full text-28')} align="center">
					{t('License Expired')}
				</Typography>
			</div>
		</Paper>
	);
};

export default ExpiredLicenseBanner;
