import axios from 'app/client';
import { getPreviousProfileId } from 'app/store/reducers';
import { AppThunk } from 'app/store';
import * as appActions from './app.actions';
import { Profile } from '../types';

export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const LOGGED_OUT_USER = 'LOGGED_OUT_USER';
export const RESET_PERSONA_UI = 'RESET_PERSONA_UI';

export const getProfile = (): AppThunk => async (dispatch, getState) => {
	const previousProfileId = getPreviousProfileId(getState());

	try {
		const { data } = await axios.get<
			any,
			{
				data: {
					licensedBy: {
						id: string;
						catalogItem: {
							id: string;
							name: string;
							uid: string;
							materialNumber: string;
						};
					};
					user: Profile;
				};
			}
		>('/api/user/session');
		const { user } = data;
		dispatch({
			type: GET_PROFILE_SUCCESS,
			payload: {
				data
			}
		});
		if (user && previousProfileId && user.id !== previousProfileId) {
			dispatch({
				type: LOGGED_OUT_USER
			});
		}
	} catch (error) {
		if (error.response?.status === 401) {
			// if user not logged in
			dispatch({
				type: GET_PROFILE_SUCCESS,
				payload: {
					data: undefined
				}
			});
			dispatch({
				type: LOGGED_OUT_USER
			});
			return;
		}
		dispatch(appActions.handleError(error));
		// re-throw error for handling in <InitializeApp />
		throw error;
	}
};

export const resetPersonaUI = (): AppThunk => async dispatch => {
	try {
		dispatch({ type: RESET_PERSONA_UI, payload: true });
		const { data } = await axios.post('/api/user/reset-customization');
		if (data.status === 'success') {
			dispatch(appActions.alert('Cleared customizations', 'success'));
		}
	} catch (e) {
		dispatch(appActions.handleError(e));
	}
	dispatch({ type: RESET_PERSONA_UI, payload: false });
};
