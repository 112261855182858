// export default () => 'wow';
import { InitialState } from 'app/store/types/panelEditor.types';
import Konva from 'konva';
import { NodeConfig } from 'konva/types/Node';
import { Group as IGroup } from 'konva/types/Group';
import * as React from 'react';
import { Group, Rect, Image, Text, Line, Circle, KonvaNodeComponent, Path } from 'react-konva';
import useImage from 'use-image';

interface IRawCanvasTileProps {
	groupProps?: typeof Group['arguments'][0];
	onClickRemove?: () => void;
	blockSize: number;
	scaleX: number;
	x: number;
	y: number;
	h: number;
	w: number;
	appearance?: InitialState['appearance'];
	name: string;
	img: HTMLImageElement | undefined;
	imgState?: ReturnType<typeof useImage>[1];
	brokenImg?: boolean;
	hideX?: boolean;
	onMouseOverX?: () => void;
	hidePinDots?: boolean;
	demo?: boolean;
	hasHeart?: boolean;
}

const RawCanvasTile = React.forwardRef<any, IRawCanvasTileProps>((props, ref) => {
	const {
		blockSize,
		scaleX,
		x,
		y,
		appearance,
		h,
		w,
		name,
		onClickRemove,
		img,
		brokenImg,
		hideX,
		onMouseOverX,
		hidePinDots,
		demo,
		imgState,
		hasHeart
	} = props;

	const getGroupX = React.useCallback((_x: number) => _x * (blockSize * scaleX), [blockSize, scaleX]);
	const getGroupY = React.useCallback(
		(_y: number) => _y * blockSize + (_y + 1) * (blockSize * 0.057) - blockSize * 0.057,
		[blockSize]
	);

	React.useEffect(() => {
		// @ts-ignore
		if (ref?.current) {
			// @ts-ignore
			ref.current.zIndex(1);
		}
	}, [ref]);

	const [heartImage] = useImage(`${process.env.PUBLIC_URL}/assets/images/app-icons/heart.svg`);

	return (
		<Group x={getGroupX(x)} y={getGroupY(y)} ref={ref} {...props.groupProps}>
			<Rect fill={appearance?.iconBackground} height={h - blockSize * 0.275} width={w} />
			<Rect fill={appearance?.textBackground} height={blockSize * 0.275} y={h - blockSize * 0.275} width={w} />

			{hasHeart && (
				<Image
					image={heartImage}
					width={blockSize * 0.15}
					height={blockSize * 0.15}
					y={h - blockSize * 0.275 - blockSize * 0.16}
					x={w - blockSize * 0.165}
					shadowEnabled
					shadowColor="#000"
					shadowOpacity={0.3}
					shadowBlur={1}
					shadowOffsetY={1}
					shadowOffsetX={1}
				/>
			)}

			{!demo ? (
				<Image
					height={blockSize * 0.5}
					width={blockSize * 0.5}
					x={(w - blockSize * 0.5) / 2}
					y={(h - blockSize * 0.275 - blockSize * 0.5) / 2}
					image={img}
					shadowEnabled
					shadowColor="#000"
					shadowOpacity={0.3}
					shadowBlur={10}
					shadowOffsetY={2}
					opacity={!brokenImg ? 1 : 0.35}
				/>
			) : (
				<Rect
					height={blockSize * 0.5}
					width={blockSize * 0.5}
					x={(w - blockSize * 0.5) / 2}
					y={(h - blockSize * 0.275 - blockSize * 0.5) / 2}
					fill="rgba(0,0,0,0.1)"
					shadowEnabled
					shadowColor="#000"
					shadowOpacity={0.3}
					shadowBlur={10}
					shadowOffsetY={2}
					opacity={!brokenImg ? 1 : 0.35}
				/>
			)}
			{!demo ? (
				<Text
					text={name}
					fontSize={Math.round(blockSize * 0.091)}
					height={blockSize * 0.275}
					y={h - blockSize * 0.275}
					width={w}
					align="center"
					verticalAlign="middle"
					fill={appearance?.fontColor}
				/>
			) : (
				<Rect
					fill="rgba(0,0,0,0.1)"
					height={blockSize * 0.125}
					y={h - blockSize * 0.2}
					x={w * 0.1}
					width={w * 0.8}
					verticalAlign="middle"
				/>
			)}
			{!hideX && (
				// eslint-disable-next-line
				<Group
					x={w - blockSize * 0.2 - 3}
					y={3}
					onClick={onClickRemove}
					onMouseOver={e => {
						e.cancelBubble = true;
						if (onMouseOverX) onMouseOverX();
					}}
					listening
				>
					<Rect
						width={blockSize * 0.2}
						height={blockSize * 0.2}
						fill="rgba(0, 0, 0, .75)"
						cornerRadius={5}
						shadowColor="rgba(0,0,0,.3)"
						shadowBlur={5}
						shadowOffset={{ x: -3, y: 3 }}
					/>
					<Line
						points={[blockSize * 0.05, blockSize * 0.05, blockSize * 0.15, blockSize * 0.15]}
						closed
						width={50}
						stroke="#fff"
						lineCap="round"
					/>
					<Line
						points={[blockSize * 0.05, blockSize * 0.15, blockSize * 0.15, blockSize * 0.05]}
						closed
						width={50}
						stroke="#fff"
						lineCap="round"
					/>
				</Group>
			)}
			{!hidePinDots && (
				<Group>
					<Circle radius={1.5} x={5} y={5} fill="rgba(0, 0, 0, .4)" />
					<Circle radius={1.5} x={5} y={11} fill="rgba(0, 0, 0, .4)" />
					<Circle radius={1.5} x={5} y={17} fill="rgba(0, 0, 0, .4)" />
					<Circle radius={1.5} x={10} y={5} fill="rgba(0, 0, 0, .4)" />
					<Circle radius={1.5} x={10} y={11} fill="rgba(0, 0, 0, .4)" />
					<Circle radius={1.5} x={10} y={17} fill="rgba(0, 0, 0, .4)" />
				</Group>
			)}
			<Rect fill={appearance?.backgroundColor} height={h} width={w} opacity={0} listening={false} />
		</Group>
	);
});

export default RawCanvasTile;
