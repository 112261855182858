import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import { RootState } from 'app/store';
import { BlacklistUsersState, BlacklistUsersActionTypes } from 'app/store/types';
import * as Actions from '../actions';

const initialState: BlacklistUsersState = {};

const blacklistUsersReducer = (state = initialState, action: BlacklistUsersActionTypes) => {
	switch (action.type) {
		case Actions.GET_LICENSE_GROUP_DATA_SUCCESS: {
			const {
				licenseGroupId,
				data: { userBlacklist: blacklistUsers }
			} = action.payload;

			return {
				...state,
				[licenseGroupId]: {
					byId: blacklistUsers
				}
			};
		}
		case Actions.LOGGED_OUT_USER:
		case Actions.PURGE_STATE:
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'blacklistUsers',
		storage: localForage
	},
	blacklistUsersReducer
);

// Selectors
export const getBlacklistUsers = ({ app: { selectedLicenseGroupId }, blacklistUsers }: RootState) =>
	Object.values(blacklistUsers[selectedLicenseGroupId].byId);
