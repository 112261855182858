import { SvgIcon, SvgIconProps } from '@material-ui/core';
import * as React from 'react';

const HeartIcon: React.FunctionComponent<SvgIconProps> = props => {
	return (
		<SvgIcon {...props}>
			<path
				d="M16.5,3c-1.7,0-3.4,0.8-4.5,2.1C10.9,3.8,9.2,3,7.5,3C4.4,3,2,5.4,2,8.5c0,3.8,3.4,6.9,8.6,11.5l1.4,1.3
	l1.4-1.3c5.2-4.7,8.6-7.8,8.6-11.5C22,5.4,19.6,3,16.5,3z M12.1,18.5L12,18.6l-0.1-0.1C7.1,14.2,4,11.4,4,8.5C4,6.5,5.5,5,7.5,5
	c1.5,0,3,1,3.6,2.4h1.9C13.5,6,15,5,16.5,5c2,0,3.5,1.5,3.5,3.5C20,11.4,16.9,14.2,12.1,18.5z"
			/>
		</SvgIcon>
	);
};

export default HeartIcon;
