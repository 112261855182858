import React from 'react';

const PanelEditorAdminConfig = {
	settings: {
		layout: {
			config: {
				adminPage: true
			}
		}
	},
	routes: [
		{
			path: '/admin/group-default',
			exact: true,
			component: React.lazy(() => import('./PanelEditor'))
		}
	]
};

export default PanelEditorAdminConfig;
