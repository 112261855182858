import axios from 'app/client';
import _ from '@lodash';
import { responseErrors } from 'app/utils/helpers';
import { getUsers, getSelectedLicenseGroupId, getUsersById, getPendingUsersById } from 'app/store/reducers';
import { AppThunk } from 'app/store';
import { User } from 'app/store/types';
import * as appActions from './app.actions';
import * as licenseGroupsActions from './licenseGroups.actions';

export const addUsers = (emails: string[], roles: User['roles']): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	try {
		const responses = await Promise.all(
			_.chunk(emails, 10).map(chunk =>
				axios.patch(`/api/license-groups/${licenseGroupId}/users`, {
					add: chunk.map(email => ({ email, roles }))
				})
			)
		);

		if (responseErrors(responses).length) {
			dispatch(appActions.alert('failed to add some users', 'warning'));
		} else {
			dispatch(appActions.alert('users updated', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const removeUsers = (emails: string[]): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const users = getUsers(getState());
	const pendingUsersById = getPendingUsersById(getState());

	// HACK-ish::split list back into "regular" and "pending" users
	const usersIds = emails
		.filter(email => !pendingUsersById[email])
		.map(email => users.find(user => user.email === email)!.id); // FIXME::by not using a dictionary this is horribly inefficient // HACK-ish:: use non-null assertion as email list comes from this list
	const pendingTokens = emails.filter(email => pendingUsersById[email]).map(email => pendingUsersById[email].token);

	try {
		const responses = await Promise.all([
			..._.chunk(usersIds, 5).map(chunk =>
				axios.patch(`/api/license-groups/${licenseGroupId}/users`, { delete: chunk })
			),
			// this is gonna make a _lot_ of requests...
			...pendingTokens.map(token => axios.delete(`/api/license-groups/${licenseGroupId}/pending-user/${token}`))
		]);
		if (responseErrors(responses).length) {
			dispatch(appActions.alert('failed to remove some users', 'warning'));
		} else {
			dispatch(appActions.alert('users removed', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const editUsers = (userIds: User['id'][], { roles: modifiedRoles }: Partial<User>): AppThunk => async (
	dispatch,
	getState
) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const usersById = getUsersById(getState());

	const mergeRoles = (userId: User['id'], newRoles: User['roles'] | undefined) => {
		return {
			...usersById[userId].roles,
			...newRoles
		};
	};

	try {
		const responses = await Promise.all(
			userIds.map(userId =>
				axios.patch(`/api/license-groups/${licenseGroupId}/user-roles`, {
					email: usersById[userId].email,
					roles: mergeRoles(userId, modifiedRoles)
				})
			)
		);
		if (responseErrors(responses).length) {
			dispatch(appActions.alert('failed to edit users', 'warning'));
		} else {
			dispatch(appActions.alert('users edited', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};
