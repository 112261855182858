import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import { RootState } from 'app/store';
import {
	LicenseGroupsState,
	LicenseGroupsActionTypes,
	LicenseGroupData,
	PublicId,
	Device,
	User,
	TransferDevice,
	TransferUser
} from 'app/store/types';
import _ from 'lodash';
import i18n from 'i18n';
import { splitName } from 'app/utils/helpers';
import * as Actions from '../actions';

const initialState: LicenseGroupsState = {
	byId: undefined!, // HACK::assume never undefined and handle check only in <LicenseGroupWrapper /> and <ToolbarLayoutAdmin />
	transferLicenseGroupData: undefined!
};

const licenseGroupsReducer = (state = initialState, action: LicenseGroupsActionTypes) => {
	switch (action.type) {
		case Actions.GET_LICENSE_GROUPS_SUCCESS: {
			return {
				...state,
				byId: action.payload.data
			};
		}
		case Actions.GET_TRANSFER_LICENSE_GROUP_DATA_SUCCESS: {
			const {
				data: { details, settings, users, devices, uis }
			} = action.payload;

			const licenseGroupData: LicenseGroupData = {
				...state.byId[details.id],
				...details,
				...settings,
				dataLoaded: true
			};
			const uiByIds = _.mapValues(uis, (value, key) => {
				return {
					id: key,
					name: value.name,
					transfer: false
				};
			});

			return {
				...state,
				transferLicenseGroupData: {
					...state.transferLicenseGroupData,
					data: licenseGroupData,
					users: {
						byId: _.mapValues(users, user => {
							return {
								...splitName(user),
								transfer: false
							};
						})
					},
					devices: {
						byId: _.mapValues(devices, device => ({
							...device,
							name: `${i18n.t([
								`product line:${device.productLine}`,
								device.productLine ?? 'product line:unknown'
							])} ${device.name}`,
							transfer: false
						}))
					},
					uis: {
						count: Object.keys(uiByIds).length,
						byId: uiByIds
					}
				}
			};
		}
		case Actions.GET_LICENSE_GROUP_DATA_SUCCESS: {
			const {
				data: { details, settings }
			} = action.payload;

			const licenseGroupData: LicenseGroupData = {
				...state.byId[details.id],
				...details,
				...settings,
				// HACK-ish::add prop to say additional data has loaded
				dataLoaded: true
			};

			return {
				...state,
				byId: {
					...state.byId,
					[details.id]: licenseGroupData
				}
			};
		}

		case Actions.ADD_DEVICE_TO_TRANSFER_LICENSE_GROUP: {
			const { devices } = action.payload;
			const transferDevices = _.map(devices, device => {
				return {
					...device,
					transfer: true
				};
			});
			const newIds = _.keyBy(transferDevices, device => device.serial) as { [deviceId: string]: TransferDevice };
			const newById = { ...state.transferLicenseGroupData?.devices.byId };

			return {
				...state,
				transferLicenseGroupData: {
					...state.transferLicenseGroupData,
					devices: {
						...state.transferLicenseGroupData?.devices,
						byId: _.merge(newById, newIds)
					}
				}
			};
		}

		case Actions.REMOVE_DEVICE_FROM_TRANSFER_LICENSE_GROUP: {
			const { devices } = action.payload;
			const newById = { ...state.transferLicenseGroupData?.devices.byId };

			return {
				...state,
				transferLicenseGroupData: {
					...state.transferLicenseGroupData,
					devices: {
						...state.transferLicenseGroupData?.devices,
						byId: _.omit(newById, devices)
					}
				}
			};
		}

		case Actions.ADD_USER_TO_TRANSFER_LICENSE_GROUP: {
			const { users } = action.payload;
			const transferUsers = _.map(users, user => {
				return {
					...user,
					transfer: true
				};
			});
			const newIds = _.keyBy(transferUsers, user => user.id) as { [userId: string]: TransferUser };
			const newById = { ...state.transferLicenseGroupData?.users.byId };

			return {
				...state,
				transferLicenseGroupData: {
					...state.transferLicenseGroupData,
					users: {
						...state.transferLicenseGroupData?.users,
						byId: _.merge(newById, newIds)
					}
				}
			};
		}

		case Actions.REMOVE_USER_FROM_TRANSFER_LICENSE_GROUP: {
			const { users } = action.payload;
			const newById = { ...state.transferLicenseGroupData?.users.byId };

			return {
				...state,
				transferLicenseGroupData: {
					...state.transferLicenseGroupData,
					users: {
						...state.transferLicenseGroupData?.users,
						byId: _.omit(newById, users)
					}
				}
			};
		}

		case Actions.ADD_UI_TO_TRANSFER_LICENSE_GROUP: {
			const { uis } = action.payload;
			const transferUis = _.map(uis, ui => {
				return {
					...ui,
					transfer: true
				};
			});
			const newIds = _.keyBy(transferUis, ui => ui.id);
			const newById = { ...state.transferLicenseGroupData?.uis.byId };

			return {
				...state,
				transferLicenseGroupData: {
					...state.transferLicenseGroupData,
					uis: {
						...state.transferLicenseGroupData?.uis,
						byId: _.merge(newById, newIds)
					}
				}
			};
		}

		case Actions.REMOVE_UI_FROM_TRANSFER_LICENSE_GROUP: {
			const { uis } = action.payload;
			const newById = { ...state.transferLicenseGroupData?.uis.byId };

			return {
				...state,
				transferLicenseGroupData: {
					...state.transferLicenseGroupData,
					uis: {
						...state.transferLicenseGroupData?.uis,
						byId: _.omit(newById, uis)
					}
				}
			};
		}

		case Actions.CLEAR_TRANSFER_DATA: {
			return {
				...state,
				transferLicenseGroupData: undefined!
			};
		}
		case Actions.LOGGED_OUT_USER:
		case Actions.PURGE_STATE:
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'licenseGroups',
		storage: localForage,
		blacklist: ['byId'] // re-request license groups every time
	},
	licenseGroupsReducer
);

// Selectors
export const getLicenseGroups = ({ licenseGroups }: RootState) =>
	licenseGroups.byId ? Object.values(licenseGroups.byId) : undefined!;
export const getSelectedLicenseGroupData = ({ app: { selectedLicenseGroupId }, licenseGroups }: RootState) =>
	licenseGroups.byId && selectedLicenseGroupId ? licenseGroups.byId[selectedLicenseGroupId] : undefined!;
export const getSelectedLicenseGroupVersion = ({ app: { selectedLicenseGroupId }, licenseGroups }: RootState) =>
	licenseGroups.byId[selectedLicenseGroupId].version;
export const getSelectedLicenseGroupOwnerId = ({ app: { selectedLicenseGroupId }, licenseGroups }: RootState) =>
	licenseGroups.byId[selectedLicenseGroupId].owner;
export const getIsSelectedLicenseGroupProductionSubscriptionLicense = ({
	app: { selectedLicenseGroupId },
	licenseGroups
}: RootState) =>
	selectedLicenseGroupId &&
	licenseGroups.byId[selectedLicenseGroupId].orderType === 'PRODUCTION' &&
	![PublicId.StarterYearlyTerm, PublicId.BusinessYearlyTerm, PublicId.EnterpriseYearlyTerm].includes(
		licenseGroups.byId[selectedLicenseGroupId].catalogPublicId
	);
export const getSelectedLicenseGroupIsExpired = ({ app: { selectedLicenseGroupId }, licenseGroups }: RootState) =>
	licenseGroups.byId && selectedLicenseGroupId ? licenseGroups.byId[selectedLicenseGroupId]?.isExpired : true;

export const getSelectedLicenseGroupLicenseName = ({ app: { selectedLicenseGroupId }, licenseGroups }: RootState) =>
	licenseGroups.byId[selectedLicenseGroupId].licenseName;

export const getSelectedTransferLicenseGroupData = ({ licenseGroups }: RootState) =>
	licenseGroups.transferLicenseGroupData?.data;

export const getTransferDevices = ({ licenseGroups }: RootState) =>
	licenseGroups.transferLicenseGroupData ? Object.values(licenseGroups.transferLicenseGroupData.devices.byId) : [];

export const getTransferUsers = ({ licenseGroups }: RootState) =>
	licenseGroups.transferLicenseGroupData ? Object.values(licenseGroups.transferLicenseGroupData.users.byId) : [];

export const getTransferUis = ({ licenseGroups }: RootState) =>
	licenseGroups.transferLicenseGroupData ? Object.values(licenseGroups.transferLicenseGroupData.uis.byId) : [];

export const getTranferUiCount = ({ licenseGroups }: RootState) =>
	licenseGroups.transferLicenseGroupData ? licenseGroups.transferLicenseGroupData.uis.count : 0;
