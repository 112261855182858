import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
// import * as authActions from 'app/auth/store/actions';
import React, { useState } from 'react';
import LogoutIcon from 'app/components/Icons/LogoutIcon';
import { getProfile, getLicenseGroups, getIsLicensedProfile } from 'app/store/reducers';
import { useSelector } from 'app/modules/react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Help from '@material-ui/icons/Help';

const UserMenu = ({ adminPage = false }) => {
	const { t, i18n } = useTranslation();
	const location = useLocation();
	const profile = useSelector(getProfile);
	const licenseGroups = useSelector(getLicenseGroups) as ReturnType<typeof getLicenseGroups> | undefined; // HACK::use actual type here - assume never undefined within the wrapped app

	const [userMenu, setUserMenu] = useState<HTMLButtonElement | null>(null);

	const userMenuClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		setUserMenu(event.currentTarget);
	};

	const userMenuClose = () => {
		setUserMenu(null);
	};

	const isLicensedProfile = useSelector(getIsLicensedProfile);

	const userPage = /^\/user(\/.*)?/.test(location.pathname);

	return (
		<>
			<Button className="h-64" onClick={userMenuClick}>
				<Avatar className="">{profile.firstName[0]}</Avatar>

				<div className="flex-col mx-12 items-start">
					<Typography component="span" className="normal-case font-600 flex">
						{profile.firstName} {profile.lastName}
					</Typography>
				</div>

				<Icon className="text-16">keyboard_arrow_down</Icon>
			</Button>

			<Popover
				open={!!userMenu}
				anchorEl={userMenu}
				onClose={userMenuClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				classes={{
					paper: 'py-8'
				}}
			>
				{(adminPage || userPage) && (
					<MenuItem component={Link} to="/" onClick={userMenuClose} role="button">
						<ListItemIcon className="min-w-40">
							<Icon>storefront</Icon>
						</ListItemIcon>
						<ListItemText primary={t('Home')} />
					</MenuItem>
				)}

				{isLicensedProfile && !userPage && (
					<MenuItem component={Link} to="/user" onClick={userMenuClose} role="button">
						<ListItemIcon className="min-w-40">
							<Icon>person</Icon>
						</ListItemIcon>
						<ListItemText primary={t('My Persona')} />
					</MenuItem>
				)}

				{!adminPage && licenseGroups && licenseGroups.length > 0 && (
					<MenuItem component={Link} to="/admin" onClick={userMenuClose} role="button">
						<ListItemIcon className="min-w-40">
							<Icon>business</Icon>
						</ListItemIcon>
						<ListItemText primary={t('Admin')} />
					</MenuItem>
				)}
				
				<a href={`${process.env.REACT_APP_HELP_URL}?locale=${i18n.language}`} target="-blank" role="button">
					<MenuItem>
						<ListItemIcon className="min-w-40">
							<Help />
						</ListItemIcon>
						<ListItemText primary={t('Help')} />
					</MenuItem>
				</a>

				<a href="/api/sso/logout" role="button">
					<MenuItem>
						<ListItemIcon className="min-w-40">
							<LogoutIcon />
						</ListItemIcon>
						<ListItemText primary={t('Logout')} />
					</MenuItem>
				</a>
			</Popover>
		</>
	);
};

export default UserMenu;
