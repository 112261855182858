import { PanelWidget, Widget } from 'app/store/types/panelEditor.types';
import intersects from './intersects';

const tileToRect = (tile: Widget, shrink?: number) => ({
	height: shrink ? tile.size.height - shrink : tile.size.height,
	width: shrink ? tile.size.width - shrink : tile.size.width,
	x: shrink ? tile.position.x + shrink / 2 : tile.position.x,
	y: shrink ? tile.position.y + shrink / 2 : tile.position.y
});

/*
  This determines if a tile can be placed inside
  of its current hover location.

  It takes into account overlapping with existing
  tiles, being out of bounds, 
*/
export type placementInfo = NonNullable<PanelWidget['placement']>;

export default (
	tile: PanelWidget,
	layout: PanelWidget[],
	container: HTMLDivElement,
	clientX: number,
	clientY: number
): placementInfo => {
	const res = {
		intersecting: false,
		intersectsKey: [],
		invalidPlacement: false,
		outOfBounds: false,
		pureX: -1,
		pureY: -1,
		...tile.position
	};

	// determine if mouse is outside of the canvas element
	// If this has a strong browser perforamnce impact we it can
	// moved to a state and updated on resize
	const rect = container.getBoundingClientRect();
	if (clientX < rect.left || clientX > rect.right || clientY < rect.top || clientY > rect.bottom) {
		res.invalidPlacement = true;
		res.outOfBounds = true;
		return res;
	}

	const xBlock = rect.width / 4;
	const yBlock = rect.height / 2;

	// pureX/Y are relative to the convas
	// x/y are the placement of the tile itself x is 0-3 and y is 0-1
	const pureX = clientX - rect.left;
	const pureY = clientY - rect.top;
	const x = Math.floor(pureX / xBlock);
	const y = Math.floor(pureY / yBlock);

	// tiles should not extend past x of 3 and y of 1, and should never be negative
	if (x + (tile.size.width - 1) > 3 || y + (tile.size.height - 1) > 1 || x < 0 || y < 0) {
		res.invalidPlacement = true;
		res.outOfBounds = true;
		res.x = x;
		res.y = y;
		return res;
	}

	const newTile: PanelWidget = {
		...tile,
		position: { x, y }
	};

	/*
    This is where we check to see if anything overlap.
    tileToRect because converts w to width and h to height
    this is the standard browser layout.

    tileToRect also takes a second paramater of how much to
    "shrink" a tile. What the does is if the height is 1 and
    0.1 is passed, it turns it into a height of 0.9 and the y
    would start at 0.05 (if it is 0). This is because if we have
    the following two rects

    {
      x: 0,
      y: 0,
      w: 1,
      h: 1
    }

    {
      x: 1,
      y: 0,
      w: 1,
      h: 1
    }

    They technical do share the same side position so there is an
    overlap. This can also be used if a component technically overlaps
    but due to styling (e.g. negative margins) it doesn't appear to
    overlap it can return false.

    We store the key of each tile that overlaps so if we late deside to
    do something with them (e.g. swap) we can.
  */
	const invalidTileByKey: string[] = [];
	// for (const layoutTile of layout) {
	// 	if (layoutTile.key === newTile.key) continue;
	// 	if (intersects(tileToRect(newTile, 0.1), tileToRect(layoutTile))) {
	// 		invalidTileByKey.push(layoutTile.key);
	// 	}
	// }

	layout.forEach(layoutTile => {
		if (layoutTile.key === newTile.key) return;
		if (intersects(tileToRect(newTile, 0.1), tileToRect(layoutTile))) {
			invalidTileByKey.push(layoutTile.key);
		}
	});

	return {
		invalidPlacement: invalidTileByKey.length > 0,
		outOfBounds: false,
		intersecting: invalidTileByKey.length > 0,
		intersectsKey: invalidTileByKey,
		pureX,
		pureY,
		x,
		y
	};
};
