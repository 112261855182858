import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Locize from 'i18next-locize-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import common from './locales/en/common.json';
import alert from './locales/en/alert.json';
import pageTitle from './locales/en/pageTitle.json';
import nativeFunctions from './locales/en/nativeFunctions.json';
import panelEditor from './locales/en/panelEditor.json';

const locize = new Locize(
	{
		projectId: 'e3bf1154-9a0b-451b-ac47-0b2ec3e61af2',
		// add an api key if you want to send missing keys
		apiKey: process.env.NODE_ENV === 'development' ? '548a9301-68cc-4a75-b86f-bdb9d3c376e7' : null,
		// version - defaults to latest
		version: process.env.REACT_APP_LOCIZE_VERSION ?? 'latest',
		whitelistThreshold: 0
	} as Locize.BackendOptions,
	// @ts-ignore // why is this not in the typings?
	(_: any, opts: i18next.InitOptions) => {
		// opts includes fallbackLng, load, referenceLng, and whitelist based on which languages we have on locize
		i18next
			.use(locize)
			.use(LanguageDetector)
			.use(initReactI18next)
			.init({
				...opts,
				debug: false,
				ns: ['common', 'alert', 'pageTitle', 'panelEditor', 'nativeFunctions'],
				defaultNS: 'common',
				returnEmptyString: false,
				keySeparator: false, // we do not use keys in form messages.welcome
				nsSeparator: '::',
				interpolation: {
					escapeValue: false
				},
				...(process.env.NODE_ENV === 'development' && {
					referenceLng: 'en',
					resources: {
						en: {
							common,
							alert,
							pageTitle,
							nativeFunctions,
							panelEditor
						}
					}
				})
			});
	}
);

// @ts-ignore // is this supposed to be `getResource`? I think it's wrong but I don't wanna change it now...
i18next.getResources = (lang, namespace) =>
	!i18next.hasResourceBundle(lang, namespace) && i18next.reloadResources(lang, namespace);

export default i18next;
