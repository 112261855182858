import { SvgIcon, SvgIconProps } from '@material-ui/core';
import * as React from 'react';

const AppIcon: React.FunctionComponent<SvgIconProps> = props => {
	return (
		<SvgIcon {...props}>
			<path d="M0 0h24v24H0z" fill="none" />
			<path d="M4 8h4V4H4v4zm6 12h4v-4h-4v4zm-6 0h4v-4H4v4zm0-6h4v-4H4v4zm6 0h4v-4h-4v4zm6-10v4h4V4h-4zm-6 4h4V4h-4v4zm6 6h4v-4h-4v4zm0 6h4v-4h-4v4z" />
		</SvgIcon>
	);
};

export default AppIcon;
