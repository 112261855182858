import AppBar from '@material-ui/core/AppBar';
import { ThemeProvider, makeStyles, createStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import * as Actions from 'app/store/actions';
import PurgeStateWrapper from 'app/components/PurgeStateWrapper';
import React from 'react';
import { useDispatch, useSelector } from 'app/modules/react-redux';
import { useTranslation } from 'react-i18next';
import { getIsPossibleDevelopStore } from 'app/store/reducers';

//
// Styles
//
const useStyles = makeStyles(theme =>
	createStyles({
		link: {
			color: '#61dafb',
			'&:hover': {
				cursor: 'pointer'
			}
		},
		separator: {
			backgroundColor: 'darkgrey',
			height: '16px',
			width: '1px',
			margin: '0px'
		}
	})
);

function FooterLayoutAdmin(props) {
	const { t, i18n } = useTranslation();
	const classes = useStyles();
	const dispatch = useDispatch();
	const footerTheme = useSelector(({ fuse }) => fuse.settings.footerTheme);

	const isPossibleDevelopStore = useSelector(getIsPossibleDevelopStore); // TEMP::skip any buttons for now develop store (as we don't know which to direct to)

	const handleOnClick = () => {
		dispatch(Actions.toggleCookieSettingsDialog(true));
	};

	return (
		<ThemeProvider theme={footerTheme}>
			<AppBar
				id="fuse-footer"
				className="relative z-10"
				color="default"
				style={{ backgroundColor: footerTheme.palette.background.default }}
			>
				<Toolbar className="flex items-center py-0 px-16 lg:px-24">
					<div className="flex flex-1">
						<Breadcrumbs separator={<Divider className={`${classes.separator}`} orientation="vertical" variant="middle" />} aria-label="breadcrumb">
						{/* NOTE: The HELP Link might come back later since this is the only place we have the link for the help */}
							{/* <Link
								color="inherit"
								href={`${process.env.REACT_APP_HELP_URL}?locale=${i18n.language}`}
								target="_blank"
								rel="noreferrer noopener"
							>
								{t('Help')}
							</Link> */}
							{/* TODO::add a contact page - maybe with a phone number as well? */}
							{!isPossibleDevelopStore && (
								<Link
									color="inherit"
									href="https://konicaminoltamarketplace.com/support/contact"
									target="_blank"
									rel="noreferrer noopener"
								>
									{t('Contact Us')}
								</Link>
							)}
							<Link to="/#" className={classes.link} onClick={handleOnClick}>
								{t('Cookie Settings')}
							</Link>
							<RouterLink to="/privacy">{t('Privacy')}</RouterLink>
							<RouterLink to="/terms">{t('Terms and Conditions')}</RouterLink>
							<RouterLink to="/eula">{t('EULA')}</RouterLink>
						</Breadcrumbs>
					</div>

					<div>
						<Typography>
							<PurgeStateWrapper>© {new Date().getFullYear()} Konica Minolta, Inc.</PurgeStateWrapper>
						</Typography>
					</div>
				</Toolbar>
			</AppBar>
		</ThemeProvider>
	);
}

export default React.memo(FooterLayoutAdmin);
