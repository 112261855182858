import * as React from 'react';
import { useSelector } from 'app/modules/react-redux';
import { getSelectedLicenseGroupData } from 'app/store/reducers';
import PolicyEnforcerContext from 'app/components/PolicyEnforcer/PolicyEnforcerContext';

const normalizeName = (name: string) =>
	String(name)
		.toLowerCase()
		.split(' ')[0];

const usePolicyEnforcer = (action: string): boolean | null => {
	const enforcer = React.useContext(PolicyEnforcerContext);
	const [state, setState] = React.useState<null | boolean>(null);
	const licenseName = normalizeName(useSelector(getSelectedLicenseGroupData)?.licenseName);

	React.useEffect(() => {
		if (!enforcer) {
			setState(null);
			return;
		}
		setState(enforcer.canPerform(licenseName, action));
	}, [action, enforcer, licenseName]);

	return state;
};

export default usePolicyEnforcer;
