import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import { RootState } from 'app/store';
import { BlacklistDevicesState, BlacklistDevicesActionTypes } from 'app/store/types';
import * as Actions from '../actions';

const initialState: BlacklistDevicesState = {};

const blacklistDevicesReducer = (state = initialState, action: BlacklistDevicesActionTypes) => {
	switch (action.type) {
		case Actions.GET_LICENSE_GROUP_DATA_SUCCESS: {
			const {
				licenseGroupId,
				data: { deviceBlacklist: blacklistDevices }
			} = action.payload;

			return {
				...state,
				[licenseGroupId]: {
					byId: blacklistDevices
				}
			};
		}
		case Actions.LOGGED_OUT_USER:
		case Actions.PURGE_STATE:
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'blacklistDevices',
		storage: localForage
	},
	blacklistDevicesReducer
);

// Selectors
export const getBlacklistDevices = ({ app: { selectedLicenseGroupId }, blacklistDevices }: RootState) =>
	Object.values(blacklistDevices[selectedLicenseGroupId].byId);
