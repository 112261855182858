/* eslint-disable import/prefer-default-export */
import MockAdapter from 'axios-mock-adapter';
import { AxiosInstance } from 'axios';

export const mocking = (instance: AxiosInstance, dontMock: boolean = false) => {
	if (process.env.NODE_ENV !== 'development' || dontMock) return;

	const mock = new MockAdapter(instance, { delayResponse: 1000 });

	// COMMENT ME TO A BE A USER
	// mock.onGet('/api/license-groups').reply(401, {
	mock.onGet('/api/license-groups').reply(200, {
		changeMeId: {
			id: 'changeMeId',
			name: 'ABC Company',
			owner: 'mock-user-id',
			domain: {
				value: 'example.net',
				pending: true
			},
			materialNumber: 'personalize',
			// assignUsersByDomain: true,
			// assignUsersByDevice: true,
			// assignDevices: true,
			licenseName: 'Enterprise',
			orderType: 'PRODUCTION',
			quantity: 7,
			capacity: 50
		},
		five: {
			id: 'five',
			name: 'XYZ Company',
			owner: 668,
			domain: {
				value: 'fake.com',
				pending: false
			},
			materialNumber: 'personalize',
			// assignUsersByDomain: true,
			// assignUsersByDevice: false,
			// assignDevices: true,
			licenseName: 'Starter',
			orderType: 'PRODUCTION',
			quantity: 9,
			capacity: 50
		},
		seven: {
			id: 'seven',
			name: 'Big Box Co.',
			owner: 670,
			domain: {
				value: '',
				pending: false
			},
			materialNumber: 'personalize',
			// assignUsersByDomain: false,
			// assignUsersByDevice: true,
			// assignDevices: false,
			licenseName: 'Bronze',
			orderType: 'PRODUCTION',
			quantity: 4,
			capacity: 25
		},
		nine: {
			id: 'nine',
			name: 'EEL Inc.',
			owner: 672,
			domain: {
				value: 'mock.com',
				pending: true
			},
			materialNumber: 'personalize',
			// assignUsersByDomain: false,
			// assignUsersByDevice: true,
			// assignDevices: false,
			licenseName: 'Bronze',
			orderType: 'PRODUCTION',
			quantity: 19,
			capacity: 25
		}
	});

	mock.onGet('/api/license-groups/changeMeId').reply(200, {
		details: {
			id: 'changeMeId',
			owner: 'mock-user-id',
			materialNumber: 'personalize',
			// assignUsersByDomain: true,
			// assignUsersByDevice: true,
			// assignDevices: true,
			licenseName: 'Enterprise',
			// catalogPublicId: '7510053C-007C-4739-B939-3D6DCAA894AF', //starter
			// catalogPublicId: '63C78DE7-EB09-4BB0-ADDF-93FEA8D9CF2F', // business
			catalogPublicId: 'D78F6F17-3717-4528-9418-A963E52FCAF1',
			orderType: 'PRODUCTION',
			quantity: 7,
			capacity: 50,
			expirationDate: 1767139200000,
			version: '2.1.3'
		},
		settings: {
			// settings: {
			name: 'ABC Company',
			domain: {
				value: 'example.net',
				pending: true
			},
			assignUsersByDevice: true,
			assignUsersByDomain: true,
			assignDevices: true
		},
		users: {
			'mock-user-id': {
				id: 'mock-user-id',
				name: 'Manny Couceiro',
				email: 'mannyc@kmbs.konicaminolta.us',
				roles: { admin: true },
				dateAdded: 1390000000000
			},
			3: {
				id: 3,
				name: 'John Smith',
				email: 'smith@example.com',
				roles: { user: true },
				dateAdded: 1410000000000
			},
			5: {
				id: 5,
				name: 'Katherine Pryde',
				email: 'pryde@example.com',
				roles: { user: true },
				dateAdded: 1430000000000
			},
			11: {
				id: 11,
				name: 'Hank McCoy',
				email: 'coy@example.com',
				roles: { user: true },
				dateAdded: 1510000000000
			},
			13: {
				id: 13,
				name: 'Eric Brooks',
				email: 'brooks@example.com',
				roles: { admin: true },
				dateAdded: 1530000000000
			},
			19: {
				id: 19,
				name: 'Reed Richards',
				email: 'richards@example.com',
				roles: { user: true, admin: true },
				dateAdded: 1580000000000
			}
		},
		pendingUsers: {
			'summers@example.com': {
				email: 'summers@example.com',
				roles: { user: true },
				dateAdded: 1450000000000,
				token: 'f024244021'
			},
			'wilson@example.com': {
				email: 'wilson@example.com',
				roles: { user: true },
				dateAdded: 1550000000000,
				token: 'f024244023'
			},
			'summersother@example.com': {
				email: 'summersother@example.com',
				roles: { admin: true },
				dateAdded: 1450000000500,
				token: 'f024244025'
			},
			'wilsonother@example.com': {
				email: 'wilsonother@example.com',
				roles: { user: true, admin: true },
				dateAdded: 1550000000000,
				token: 'f024244027'
			}
		},
		userBlacklist: {
			'storm@example.com': {
				name: '558',
				email: 'storm@example.com',
				dateAdded: 1586662926607
			},
			'barton@example.com': {
				name: '568',
				email: 'barton@example.com',
				dateAdded: 1586662926627
			},
			'stormother@example.com': {
				name: '578i',
				email: 'stormother@example.com',
				dateAdded: 1586662926647
			},
			'bartonother@example.com': {
				name: '588',
				email: 'bartonother@example.com',
				dateAdded: 1586662926667
			},
			'brooks@example.com': {
				name: '588',
				email: 'brooks@example.com',
				dateAdded: 1586662927667
			}
		},
		devices: {
			A61F011000003: {
				id: 3,
				name: '287',
				friendlyName: 'Crocodile',
				generation: 'IT6',
				productLine: 'bizhub',
				serial: 'A61F011000003',
				dateAdded: 1420000000000
			},
			A61F011000005: {
				id: 5,
				name: '364e',
				friendlyName: 'Ostrich',
				generation: 'IT6',
				productLine: 'bizhub',
				serial: 'A61F011000005',
				dateAdded: 1440000000000
			},
			A61F011000007: {
				id: 7,
				name: '364e',
				friendlyName: 'Beetle',
				generation: 'IT6',
				productLine: 'bizhub',
				serial: 'A61F011000007',
				dateAdded: 1460000000000
			},
			A61F011000009: {
				id: 9,
				name: '4052',
				friendlyName: '',
				generation: 'IT6',
				productLine: 'bizhub',
				serial: 'A61F011000009',
				dateAdded: 1480000000000
			},
			A61F011000011: {
				id: 11,
				name: '4750',
				friendlyName: '',
				generation: 'IT6',
				productLine: 'bizhub',
				serial: 'A61F011000011',
				dateAdded: 1520000000000
			},
			A61F011000013: {
				id: 13,
				name: 'C287',
				friendlyName: 'Godzilla',
				generation: 'IT6',
				productLine: 'bizhub',
				serial: 'A61F011000013',
				dateAdded: 1540000000000
			},
			A61F011000015: {
				id: 15,
				name: 'C287',
				friendlyName: 'Shark',
				generation: 'IT6',
				productLine: 'bizhub',
				serial: 'A61F011000015',
				dateAdded: 1560000000000
			},
			A61F011000017: {
				id: 17,
				name: 'C300i',
				friendlyName: '',
				generation: 'IT6',
				productLine: 'bizhub',
				serial: 'A61F011000017',
				dateAdded: 1570000000000
			},
			A61F011000619: {
				id: 19,
				name: 'C666',
				friendlyName: '',
				generation: 'IT6',
				productLine: 'bizhub',
				serial: 'A61F011000619',
				dateAdded: 1580000000000
			}
		},

		deviceBlacklist: {
			A61F011000719: {
				name: 'C260i',
				serial: 'A61F011000719',
				dateAdded: 1580000000000
			},
			A61F011000819: {
				name: 'C350i',
				serial: 'A61F011000819',
				dateAdded: 1580000000000
			}
		}
	});

	mock.onDelete('/api/license-groups/changeMeId').reply(200, { success: true });
	// mock.onDelete('/api/license-groups/changeMeId').reply(500, []);

	mock.onGet('/api/user/session').reply(200, {
		licensedBy: {
			id: 'changeMeId',
			name: 'LICENSE_GROUP_NAME',
			catalogItem: {
				id: 'D78F6F17-3717-4528-9418-A963E52FCAF1', // Catalog item public ID
				name: 'Enterprise',
				uid: 'sp-en-subm',
				materialNumber: 'ADGGA03'
			}
		},
		user: {
			id: 'mock-user-id',
			firstName: 'Manny',
			lastName: 'Couceiro',
			email: 'mannyc@kmbs.konicaminolta.us',
			country: 'US'
		}
	});
	// mock.onGet('/api/user/session').reply(401, null);

	mock.onGet('/api/user/devices').reply(200, [
		{
			name: 'C558',
			serial: 'A61F011400204'
		},
		{
			name: 'C556',
			serial: 'A61F0114002047'
		},
		{
			name: 'C554',
			serial: 'A61F011400234'
		},
		{
			name: 'C358',
			friendlyName: 'Leopard',
			serial: 'A31F011400204'
		},
		{
			name: 'C356',
			serial: 'A31F0114002047'
		},
		{
			name: 'C354',
			friendlyName: 'Tiger',
			serial: 'A31F011400234'
		},
		{
			name: 'C666',
			serial: 'A61F011000619'
		}
	]);

	// mock.onPatch('/api/license-groups/changeMeId/users').reply(200, {
	// 	errors: {
	// 		add: [
	// 			{ type: 'already-associated', data: { email: 'fake1@email.com' } },
	// 			{ type: 'already-associated', data: { email: 'fake2@email.com' } }
	// 		],
	// 		delete: []
	// 	}
	// });
	mock.onPatch('/api/license-groups/changeMeId/users').reply(200, { errors: { add: [], delete: [] } });
	mock.onPatch('/api/license-groups/changeMeId/user-blacklist').reply(200, { success: 'true' });
	mock.onPatch('/api/license-groups/changeMeId/devices').reply(200, { success: 'true' });
	mock.onPatch('/api/license-groups/changeMeId/device-blacklist').reply(200, { success: 'true' });
	mock.onPatch('/api/license-groups/changeMeId/settings').reply(200, { success: 'true' });
	// mock.onPatch('/api/license-groups/changeMeId/settings').reply(401, { success: 'true' });
	// mock.onPatch('/api/license-groups/changeMeId/settings').reply(200, {
	// 	errors: [
	// 		{
	// 			output: {
	// 				statusCode: 400
	// 			}
	// 		}
	// 	]
	// });
	mock.onPost('/api/license-groups/changeMeId/registration').reply(200, { success: 'true' });

	mock.onGet('/geolocation').reply(200, 'US');

	mock.onGet('/mp/catalog/pricing?region=US').reply(200, {
		region: 'US',
		currencyCode: 'USD',
		pricing: {
			'009EC905-B759-41E8-943E-C9B6913E18F8': {
				publicId: '009EC905-B759-41E8-943E-C9B6913E18F8',
				name: 'Personalize Service - Starter Plan',
				showPrice: true,
				priceRanges: [
					{
						sapId: 'PS001M1',
						amount: 0,
						min: 1
					}
				],
				billing: {
					name: 'One Time Fee',
					uid: 'bonce',
					duration: 0,
					months: false,
					gracePeriod: 0
				}
			},
			'7510053C-007C-4739-B939-3D6DCAA894AF': {
				publicId: '7510053C-007C-4739-B939-3D6DCAA894AF',
				name: 'Personalize Service - Starter Plan (5 users, monthly)',
				showPrice: true,
				priceRanges: [
					{
						sapId: 'ADGGA01',
						amount: 2.5,
						min: 5
					}
				],
				billing: {
					name: 'Monthly',
					uid: 'bmonth',
					duration: 1,
					months: true,
					gracePeriod: 0
				},
				counter: {
					min: 5,
					max: 10_000,
					step: 5,
					lock: false
				}
			},
			'63C78DE7-EB09-4BB0-ADDF-93FEA8D9CF2F': {
				publicId: '63C78DE7-EB09-4BB0-ADDF-93FEA8D9CF2F',
				name: 'Personalize Service - Business Plan (5 users, monthly)',
				showPrice: true,
				priceRanges: [
					{
						sapId: 'ADGGA02',
						amount: 5,
						min: 5
					}
				],
				billing: {
					name: 'Monthly',
					uid: 'bmonth',
					duration: 1,
					months: true,
					gracePeriod: 0
				},
				counter: {
					min: 5,
					max: 10_000,
					step: 5,
					lock: false
				}
			},
			'D78F6F17-3717-4528-9418-A963E52FCAF1': {
				publicId: 'D78F6F17-3717-4528-9418-A963E52FCAF1',
				name: 'Personalize Service - Enterprise Plan (5 users, monthly)',
				showPrice: true,
				priceRanges: [
					{
						sapId: 'ADGGA03',
						amount: 7.5,
						min: 5
					}
				],
				billing: {
					name: 'Monthly',
					uid: 'bmonth',
					duration: 1,
					months: true,
					gracePeriod: 0
				},
				counter: {
					min: 5,
					max: 10_000,
					step: 5,
					lock: false
				}
			},
			'8DD213B8-349D-42C0-8E20-122F8628117F': {
				publicId: '8DD213B8-349D-42C0-8E20-122F8628117F',
				name: 'Personalize Service - Starter Plan (5 users, 1-year term)',
				showPrice: true,
				priceRanges: [
					{
						sapId: 'ADGGA07',
						amount: 42,
						min: 5
					}
				],
				billing: {
					name: 'One Time Fee',
					uid: 'bonce',
					duration: 0,
					months: false,
					gracePeriod: 0
				},
				counter: {
					min: 5,
					max: 10_000,
					step: 5,
					lock: false
				}
			},
			'2B5C72E7-58BD-4E41-8A0A-E00858E19780': {
				publicId: '2B5C72E7-58BD-4E41-8A0A-E00858E19780',
				name: 'Personalize Service - Business Plan (5 users, 1-year term)',
				showPrice: true,
				priceRanges: [
					{
						sapId: 'ADGGA08',
						amount: 72,
						min: 5
					}
				],
				billing: {
					name: 'One Time Fee',
					uid: 'bonce',
					duration: 0,
					months: false,
					gracePeriod: 0
				},
				counter: {
					min: 5,
					max: 10_000,
					step: 5,
					lock: false
				}
			},
			'361F9B6A-690D-4EE0-AC0D-792DB9912F40': {
				publicId: '361F9B6A-690D-4EE0-AC0D-792DB9912F40',
				name: 'Personalize Service - Enterprise Plan (5 users, 1-year term)',
				showPrice: true,
				priceRanges: [
					{
						sapId: 'ADGGA09',
						amount: 102,
						min: 5
					}
				],
				billing: {
					name: 'One Time Fee',
					uid: 'bonce',
					duration: 0,
					months: false,
					gracePeriod: 0
				},
				counter: {
					min: 5,
					max: 10_000,
					step: 5,
					lock: false
				}
			},
			'13421508-5D29-4D7E-A81C-7E3650A029EA': {
				publicId: '13421508-5D29-4D7E-A81C-7E3650A029EA',
				name: 'Personalize Service - Starter Plan (5 users, yearly)',
				showPrice: true,
				priceRanges: [
					{
						sapId: 'ADGGA04',
						amount: 18,
						min: 5
					}
				],
				billing: {
					name: 'Yearly',
					uid: 'byear',
					duration: 12,
					months: true,
					gracePeriod: 0
				},
				counter: {
					min: 5,
					max: 100,
					step: 5,
					lock: false
				}
			},
			'A33092BF-C2ED-4382-8DE9-6CDA1BC8C42B': {
				publicId: 'A33092BF-C2ED-4382-8DE9-6CDA1BC8C42B',
				name: 'Personalize Service - Business Plan (5 users, yearly)',
				showPrice: true,
				priceRanges: [
					{
						sapId: 'ADGGA05',
						amount: 48,
						min: 5
					}
				],
				billing: {
					name: 'Yearly',
					uid: 'byear',
					duration: 12,
					months: true,
					gracePeriod: 0
				},
				counter: {
					min: 5,
					max: 100,
					step: 5,
					lock: false
				}
			},
			'8BB0F434-C4ED-4049-AAA6-8F473FCB7483': {
				publicId: '8BB0F434-C4ED-4049-AAA6-8F473FCB7483',
				name: 'Personalize Service - Enterprise Plan (5 users, yearly)',
				showPrice: true,
				priceRanges: [
					{
						sapId: 'ADGGA06',
						amount: 78,
						min: 5
					}
				],
				billing: {
					name: 'Yearly',
					uid: 'byear',
					duration: 12,
					months: true,
					gracePeriod: 0
				},
				counter: {
					min: 5,
					max: 100,
					step: 5,
					lock: false
				}
			},
			'84870BF9-5A03-4F0C-B128-1A112BA98E9D': {
				publicId: '84870BF9-5A03-4F0C-B128-1A112BA98E9D',
				name: 'Personalize',
				showPrice: true,
				priceRanges: [
					{
						sapId: 'p8e',
						amount: 0,
						min: 1
					}
				],
				billing: {
					name: 'One Time Fee',
					uid: 'bonce',
					duration: 0,
					months: false,
					gracePeriod: 0
				},
				counter: {
					min: 1,
					max: 1,
					step: 1,
					lock: true
				}
			}
		}
	});

	mock.onGet('/mp/catalog/pricing/D78F6F17-3717-4528-9418-A963E52FCAF1?license_group=changeMeId').reply(200, {
		total: 140.5,
		currencyCode: 'USD'
	});
	mock.onGet('/mp/catalog/pricing/D78F6F17-3717-4528-9418-A963E52FCAF1?license_group=changeMeId&quantity=100').reply(
		200,
		{
			total: 180.5,
			currencyCode: 'USD'
		}
	);

	// Upgrade the subscription plan from Business to Enterprise
	// Get current plan (Business)
	mock.onGet('/mp/catalog/pricing/63C78DE7-EB09-4BB0-ADDF-93FEA8D9CF2F?license_group=changeMeId').reply(200, {
		total: 140.5,
		currencyCode: 'USD'
	});
	// Get new plan (Enterprise)
	mock.onGet('/mp/catalog/pricing/D78F6F17-3717-4528-9418-A963E52FCAF1?license_group=changeMeId&quantity=50').reply(
		200,
		{
			total: 180.5,
			currencyCode: 'USD'
		}
	);

	mock.onGet('/api/user/panel-data').reply(200, {
		backgroundColor: '#FAFAFA',
		backgroundImageUrl:
			'https://image.freepik.com/free-vector/shiny-white-gray-background-with-wavy-lines_1017-25101.jpg',
		fontColor: '#1E1E1E',
		language: 'English',
		widgetAppearance: {
			iconBackground: '#E6E6E6AA',
			textBackground: 'rgba(250, 250, 250, 0.6)'
		},
		layoutPages: [
			[
				{
					id: 'w00000017',
					position: {
						x: 0,
						y: 0
					},
					size: {
						height: 1,
						width: 1
					}
				},
				{
					id: 'w00000202',
					position: {
						x: 0,
						y: 1
					},
					size: {
						height: 1,
						width: 1
					}
				}
			],
			[
				{
					id: 'w00000017',
					position: {
						x: 0,
						y: 0
					},
					size: {
						height: 2,
						width: 1
					}
				},
				{
					id: 'w00000017',
					position: {
						x: 1,
						y: 1
					},
					size: {
						height: 1,
						width: 1
					}
				},
				{
					id: 'w00000017',
					position: {
						x: 1,
						y: 0
					},
					size: {
						height: 1,
						width: 1
					}
				}
			]
		]
	});
	mock.onPut('/api/user/panel-data').reply(200);
	mock.onGet('/api/user/functions').reply(200, {
		static: {
			w00000001: { name: 'Copy', class: 'icn-copy' },
			w00000002: { name: 'Scan To Email', class: 'icn-email' },
			w00000003: { name: 'Scan To Box', class: 'icn-box' },
			w00000004: { name: 'Scan To PC', class: 'icn-scanpc' },
			w00000005: { name: 'Internet Fax', class: 'icn-inetfax' },
			w00000006: { name: 'IP Address Fax', class: 'icn-ipfax' },
			w00000007: { name: 'Fax', class: 'icn-fax' },
			w00000016: { name: 'IP Fax (SIP)', class: 'icn-ipaddrfax' },
			w00000017: { name: 'Quick Copy', class: 'icn-quick-copy' },
			w00000101: { name: 'Web Browser', class: 'icn-web' },
			w00000102: {
				name: 'MarketPlace',
				class: 'icn-mpinstall'
			}, // pre-install.
			w00000201: { name: 'Address Book', class: 'icn-address' },
			w00000202: { name: 'USB Mem. Print', class: 'icn-usb' },
			w00000203: { name: 'Classic Style', class: 'icn-classic' },
			w00000204: { name: 'Classic Copy', class: 'icn-classic-copy' },
			w00000205: { name: 'Classic Scan', class: 'icn-classic-scan' },
			w00000206: { name: 'Classic Fax', class: 'icn-classic-fax' },
			w00000207: { name: 'Box', class: 'icn-classic-box' },
			w00000208: { name: 'Serverless Pull Printing', skip: true },
			w00000301: { name: 'ID & Print', class: 'icn-idprint' },
			w00000302: { name: 'Paper', class: 'icn-paper' },
			w00000303: { name: 'Card Shot', class: 'icn-idcard' },
			w00000304: { name: 'Scan to Me', class: 'icn-scanme' },
			w00000401: { name: 'APP (OpenAPI)', class: 'icn-app' }
		}
	});
	mock.onGet('/api/user/panel-background').reply(200, [
		`${process.env.PUBLIC_URL}/assets/images/panel-backgrounds/appreciate.png`,
		`${process.env.PUBLIC_URL}/assets/images/panel-backgrounds/branch.png`,
		`${process.env.PUBLIC_URL}/assets/images/panel-backgrounds/brave.png`,
		`${process.env.PUBLIC_URL}/assets/images/panel-backgrounds/circuit.png`,
		`${process.env.PUBLIC_URL}/assets/images/panel-backgrounds/confusion.png`,
		`${process.env.PUBLIC_URL}/assets/images/panel-backgrounds/conservation.png`,
		`${process.env.PUBLIC_URL}/assets/images/panel-backgrounds/echo.png`,
		`${process.env.PUBLIC_URL}/assets/images/panel-backgrounds/heat.png`,
		`${process.env.PUBLIC_URL}/assets/images/panel-backgrounds/prospect.png`,
		`${process.env.PUBLIC_URL}/assets/images/panel-backgrounds/soft.png`,
		`${process.env.PUBLIC_URL}/assets/images/panel-backgrounds/timber.png`,
		`${process.env.PUBLIC_URL}/assets/images/panel-backgrounds/understanding.png`
	]);
	mock.onGet('/api/user/default-layout').reply(200, [
		[
			{
				id: 'w00000017',
				position: {
					x: 0,
					y: 0
				},
				size: {
					height: 1,
					width: 1
				}
			},
			{
				id: 'w00000202',
				position: {
					x: 0,
					y: 1
				},
				size: {
					height: 1,
					width: 1
				}
			}
		]
	]);

	mock.onGet('/api/reporting/license-group-usage?from=2020-11-01&to=2020-11-10&grp=device&id=changeMeId').reply(200, [
		{ device_name: 'Alpha Ronald 100', device_id: '13fhhi70ehf', users_using_ct: '11230', auth_count: '3133' },
		{ device_name: 'Bravo', device_id: '241eih11499', users_using_ct: '1340', auth_count: '3203' },
		{ device_name: 'Charlie', device_id: '30jgij44b5d', users_using_ct: '140', auth_count: '3138' },
		{ device_name: 'Delta', device_id: '4fcech302hc', users_using_ct: '104', auth_count: '3281' },
		{ device_name: 'Elos', device_id: '5fifbha1e53', users_using_ct: '1044', auth_count: '3295' }
	]);

	mock.onGet('/api/reporting/license-group-usage?from=2020-11-09&to=2020-11-10&grp=device&id=changeMeId').reply(200, [
		{ device_name: 'Alpha Ronald 10', device_id: '13fhhi70ehf', users_using_ct: '11230', auth_count: '3133' },
		{ device_name: 'Bravo', device_id: '241eih11499', users_using_ct: '1340', auth_count: '3203' },
		{ device_name: 'Charlie', device_id: '30jgij44b5d', users_using_ct: '140', auth_count: '3138' },
		{ device_name: 'Delta', device_id: '4fcech302hc', users_using_ct: '104', auth_count: '3281' },
		{ device_name: 'Elos', device_id: '5fifbha1e53', users_using_ct: '1044', auth_count: '3295' }
	]);

	mock.onGet('/api/reporting/license-group-usage?from=2020-11-01&to=2020-11-10&grp=user&id=changeMeId').reply(200, [
		{
			user_name: 'alice',
			user_id: '001b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '005',
			auth_count: '1604'
		},
		{
			user_name: 'ben',
			user_id: '002b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '123125',
			auth_count: '1659'
		},
		{
			user_name: 'casay',
			user_id: '003b5d80ahjg08815gif5290901i1aac5bdhf3d7',
			devices_used_ct: '655',
			auth_count: '1545'
		},
		{
			user_name: 'derek',
			user_id: '004b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '45',
			auth_count: '1516'
		},
		{
			user_name: 'evan',
			user_id: '005b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '335',
			auth_count: '1639'
		},
		{
			user_name: 'frank',
			user_id: '006b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '15',
			auth_count: '1578'
		},
		{
			user_name: 'george',
			user_id: '007b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '25',
			auth_count: '1635'
		},
		{
			user_name: 'henry',
			user_id: '008b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '56',
			auth_count: '1585'
		},
		{
			user_name: 'issac',
			user_id: '009b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '54',
			auth_count: '1639'
		},
		{
			user_name: 'jerry',
			user_id: '010b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '51',
			auth_count: '1650'
		}
	]);

	mock.onGet('/api/reporting/license-group-usage?from=2020-11-09&to=2020-11-10&grp=user&id=changeMeId').reply(200, [
		{
			user_name: 'alice1',
			user_id: '001b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '05',
			auth_count: '164'
		},
		{
			user_name: 'ben2',
			user_id: '002b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '5',
			auth_count: '1659'
		},
		{
			user_name: 'casay',
			user_id: '003b5d80ahjg08815gif5290901i1aac5bdhf3d7',
			devices_used_ct: '5',
			auth_count: '155'
		},
		{
			user_name: 'derek1',
			user_id: '004b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '5',
			auth_count: '16'
		},
		{
			user_name: 'evan',
			user_id: '005b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '5',
			auth_count: '5'
		},
		{
			user_name: 'frank',
			user_id: '006b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '15',
			auth_count: '78'
		},
		{
			user_name: 'george',
			user_id: '007b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '25',
			auth_count: '35'
		},
		{
			user_name: 'henry',
			user_id: '008b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '56',
			auth_count: '1585'
		},
		{
			user_name: 'issac',
			user_id: '009b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '54',
			auth_count: '1639'
		},
		{
			user_name: 'jerry',
			user_id: '010b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '51',
			auth_count: '1650'
		}
	]);

	mock.onGet('/api/reporting/license-group-usage?from=2020-11-01&to=2020-11-10&grp=user&by=week&id=changeMeId').reply(
		200,
		[
			{
				user_name: 'alice',
				user_id: '001b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '14',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'alice',
				user_id: '001b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '12',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'ben',
				user_id: '002b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '19',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'ben',
				user_id: '002b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '10',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'casay',
				user_id: '003b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '131',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'casay',
				user_id: '003b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '14',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'derek',
				user_id: '004b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '16',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'derek',
				user_id: '004b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '10',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'evan',
				user_id: '005b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '11',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'evan',
				user_id: '005b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '18',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'frank',
				user_id: '006b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '46',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'frank',
				user_id: '006b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '32',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'george',
				user_id: '007b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '25',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'george',
				user_id: '007b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '10',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'henry',
				user_id: '008b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '186',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'henry',
				user_id: '008b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '9',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'issac',
				user_id: '009b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '12',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'issac',
				user_id: '009b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '17',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'jerry',
				user_id: '010b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '32',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'jerry',
				user_id: '010b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '1',
				by_week: '2020-10-26T00:00:00.000Z'
			}
		]
	);

	mock.onGet(
		'/api/reporting/license-group-usage?from=2020-11-01&to=2020-11-10&grp=device&by=week&id=changeMeId'
	).reply(200, [
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '2883',
			by_week: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '250',
			by_week: '2020-10-26T00:00:00.000Z'
		},
		{
			device_name: 'Bravo',
			device_id: '241eih11499',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '2976',
			by_week: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Bravo',
			device_id: '241eih11499',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '227',
			by_week: '2020-10-26T00:00:00.000Z'
		},
		{
			device_name: 'Charlie',
			device_id: '30jgij44b5d',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '2899',
			by_week: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Charlie',
			device_id: '30jgij44b5d',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '239',
			by_week: '2020-10-26T00:00:00.000Z'
		},
		{
			device_name: 'Delta',
			device_id: '4fcech302hc',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '3043',
			by_week: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Delta',
			device_id: '4fcech302hc',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '238',
			by_week: '2020-10-26T00:00:00.000Z'
		},
		{
			device_name: 'Elos',
			device_id: '5fifbha1e53',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '3049',
			by_week: '2020-10-19T00:00:00.000Z'
		}
	]);

	mock.onGet(
		'/api/reporting/license-group-usage?from=2020-11-09&to=2020-11-10&grp=device&by=day&id=changeMeId'
	).reply(200, [
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '3',
			users_using_ct: '3',
			auth_count: '283',
			by_day: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '22',
			by_day: '2020-10-26T00:00:00.000Z'
		},
		{
			device_name: 'Bravo',
			device_id: '241eih11499',
			users_using: '3',
			users_using_ct: '3',
			auth_count: '29',
			by_day: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Bravo',
			device_id: '241eih11499',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '22',
			by_day: '2020-10-26T00:00:00.000Z'
		},
		{
			device_name: 'Charlie',
			device_id: '30jgij44b5d',
			users_using: '1',
			users_using_ct: '1',
			auth_count: '28',
			by_day: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Charlie',
			device_id: '30jgij44b5d',
			users_using: '3',
			users_using_ct: '3',
			auth_count: '23',
			by_day: '2020-10-26T00:00:00.000Z'
		},
		{
			device_name: 'Delta',
			device_id: '4fcech302hc',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '3043',
			by_day: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Delta',
			device_id: '4fcech302hc',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '238',
			by_day: '2020-10-26T00:00:00.000Z'
		},
		{
			device_name: 'Elos',
			device_id: '5fifbha1e53',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '3049',
			by_day: '2020-10-19T00:00:00.000Z'
		}
	]);

	mock.onGet(
		'/api/reporting/license-group-usage?from=2020-11-01&to=2020-11-10&grp=device&by=week&devicefilt=13fhhi70ehf&id=changeMeId'
	).reply(200, [
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '2883',
			by_week: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '250',
			by_week: '2020-10-26T00:00:00.000Z'
		}
	]);

	mock.onGet(
		'/api/reporting/license-group-usage?from=2020-11-01&to=2020-11-10&grp=device&by=week&devicefilt=241eih11499&id=changeMeId'
	).reply(200, [
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '14',
			users_using_ct: '11',
			auth_count: '83',
			by_week: '2020-10-01T00:00:00.000Z'
		},
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '22',
			users_using_ct: '13',
			auth_count: '250',
			by_week: '2020-10-0500:00:00.000Z'
		},
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '22',
			users_using_ct: '33',
			auth_count: '20',
			by_week: '2020-10-12T00:00:00.000Z'
		},
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '22',
			users_using_ct: '23',
			auth_count: '10',
			by_week: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '22',
			users_using_ct: '3',
			auth_count: '52',
			by_week: '2020-10-26T00:00:00.000Z'
		}
	]);

	mock.onGet('/api/license-policy').reply(200, {
		starter: ['admin-dashboard', 'add-users', 'add-devices'],
		business: ['admin-dashboard', 'add-users', 'add-devices', 'panel-editor'],
		enterprise: [
			'admin-dashboard',
			'add-users',
			'add-devices',
			'panel-editor',
			'assign-devices-by-user',
			'assign-users-by-device',
			'assign-users-by-domain',
			'block-users',
			'block-devices',
			'admin-dashboard-ext',
			'reports'
		]
	});
};
