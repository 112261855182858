import axios from 'app/client';
import _ from '@lodash';
import { responseErrors } from 'app/utils/helpers';
import { getAllUsers, getSelectedLicenseGroupId } from 'app/store/reducers';
import { AppThunk } from 'app/store';
import { User } from 'app/store/types';
import * as appActions from './app.actions';
import * as licenseGroupsActions from './licenseGroups.actions';
import * as usersActions from './users.actions';

export const addBlacklistUsers = (emails: string[]): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const users = getAllUsers(getState());

	try {
		// TODO::make this argument into a function
		const existingUsers = users.filter(user => emails.includes(user.email));
		const existingUsersWithUserRoleOnly = existingUsers.filter(
			user => user.roles.user && Object.values(user.roles).filter(Boolean).length === 1
		);
		const existingUsersWithUserRoleAndOthers = existingUsers.filter(
			user => user.roles.user && Object.values(user.roles).filter(Boolean).length > 1
		);

		if (existingUsersWithUserRoleOnly.length) {
			await dispatch(usersActions.removeUsers(existingUsersWithUserRoleOnly.map(({ email }) => email)));
		}
		if (existingUsersWithUserRoleAndOthers.length) {
			await dispatch(
				usersActions.editUsers(
					// only support editing non-pending users ATM
					(existingUsersWithUserRoleAndOthers.filter(user => !user.pending) as User[]).map(user => user.id),
					{ roles: { user: false } }
				)
			);
		}
		const responses = await Promise.all(
			_.chunk(emails, 25).map(chunk =>
				axios.patch(`/api/license-groups/${licenseGroupId}/user-blacklist`, { add: chunk })
			)
		);
		if (responseErrors(responses).length) {
			dispatch(appActions.alert('failed to blacklist some users', 'warning'));
		} else {
			dispatch(appActions.alert('user blacklist updated', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const removeFromBlacklistUsers = (emails: string[]): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	try {
		const responses = await Promise.all(
			_.chunk(emails, 25).map(chunk =>
				axios.patch(`/api/license-groups/${licenseGroupId}/user-blacklist`, { delete: chunk })
			)
		);
		if (responseErrors(responses).length) {
			dispatch(appActions.alert('failed to remove some users from blacklist', 'warning'));
		} else {
			dispatch(appActions.alert('users removed from blacklist', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};
