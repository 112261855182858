import axios from 'app/client';
import { AppThunk } from 'app/store';
import { getRange, getDateSpan, getFilter, getSelectedLicenseGroupId, getOffset } from 'app/store/reducers';
import _ from '@lodash';
import * as Actions from './app.actions';
import { DateRange, DateSpan, ReportRange, QueryRange, ReportFilterType, ReportFilterId } from '../types';

export const SET_UTC_OFFSET = 'SET_UTC_OFFSET';
export const SET_DATE_SPAN = 'SET_DATE_SPAN';
export const SET_DATE_RANGE = 'SET_DATE_RANGE';
export const GET_USERS_AND_DEVICES_SUCCESS = 'GET_USERS_AND_DEVICES_SUCCESS';
export const GET_USERS_AND_DEVICES = 'GET_USERS_AND_DEVICES';
export const SET_REPORT_FILTER_TYPE = 'SET_REPORT_FILTER_TYPE';
export const ADD_REPORT_FILTER = 'ADD_REPORT_FILTER';
export const REMOVE_REPORT_FILTER = 'REMOVE_REPORT_FILTER';
export const GET_FILTERED_REPORT_DATA_SUCCESS = 'GET_FILTERED_REPORT_DATA_SUCCESS';
export const GET_FILTERED_REPORT_DATA = 'GET_FILTERED_REPORT_DATA';
export const GET_REPORT_DATA_SUCCESS = 'GET_REPORT_DATA_SUCCESS';
export const GET_REPORT_DATA = 'GET_REPORT_DATA';
export const CLEAR_FILTERED_REPORT_DATA = 'CLEAR_FILTERED_REPORT_DATA';
export const SET_ZOOM_IN = 'SET_ZOOM_IN';
export const GET_USER_OR_DEVICE_REPORT_DATA = 'GET_USER_OR_DEVICE_REPORT_DATA';
export const GET_USER_OR_DEVICE_REPORT_DATA_SUCCESS = 'GET_USER_OR_DEVICE_REPORT_DATA_SUCCESS';
export const CLEAR_USER_OR_DEVICE_REPORT_DATA = 'CLEAR_USER_OR_DEVICE_REPORT_DATA';
export const REPORT_ERROR = 'REPORT_ERROR';
export const SET_CHART_LOADING = 'SET_CHART_LOADING';
export const SET_FILTER_LOADING = 'SET_FILTER_LOADING';
export const SET_DETAIL_LOADING = 'SET_DETAIL_LOADING';
export const RESET_REPORT_STATE = 'RESET_REPORT_STATE';
export const GET_TOP_USERS = 'GET_TOP_USERS';
export const GET_TOP_USERS_SUCCESS = 'GET_TOP_USERS_SUCCESS';
export const GET_TOP_MFPS = 'GET_TOP_MFPS';
export const GET_TOP_MFPS_SUCCESS = 'GET_TOP_MFPS_SUCCESS';

export const getTopUsers = (start: string, end: string): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const { to, from, offset } = getOffset(getState());

	dispatch({
		type: GET_TOP_USERS
	});

	// console.log(
	// 	`/api/reporting/license-group-usage?from=${start}${from}&to=${end}${to}&level=popular&by=month&id=${licenseGroupId}&view=user&top=10&offset=${offset}`
	// );

	try {
		const { data } = await axios.get(
			`/api/reporting/license-group-usage?from=${start}${from}&to=${end}${to}&level=popular&by=month&id=${licenseGroupId}&view=user&top=10&offset=${offset}`
		);

		const { report } = data;

		dispatch({
			type: GET_TOP_USERS_SUCCESS,
			payload: parseTop(report, 'users')
		});
	} catch (error) {
		dispatch(Actions.alert('Failed to retrieve top user data', 'error'));
	}
};

export const getTopMfps = (start: string, end: string): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const { to, from, offset } = getOffset(getState());

	dispatch({
		type: GET_TOP_MFPS
	});

	// console.log(
	// 	`/api/reporting/license-group-usage?from=${start}${from}&to=${end}${to}&level=popular&by=month&id=${licenseGroupId}&view=device&top=10&offset=${offset}`
	// );

	try {
		const { data } = await axios.get(
			`/api/reporting/license-group-usage?from=${start}${from}&to=${end}${to}&level=popular&by=month&id=${licenseGroupId}&view=device&top=10&offset=${offset}`
		);

		const { report } = data;

		dispatch({
			type: GET_TOP_MFPS_SUCCESS,
			payload: parseTop(report, 'device')
		});
	} catch (error) {
		dispatch(Actions.alert('Failed to retrieve top device data', 'error'));
	}
};

export const resetReportState = () => {
	return {
		type: RESET_REPORT_STATE
	};
};

export const setUTCOffset = (to: string, from: string, offset: string) => {
	return {
		type: SET_UTC_OFFSET,
		payload: {
			offset: {
				to,
				from,
				offset
			}
		}
	};
};

export const setFilterLoading = (loading: boolean) => {
	return {
		type: SET_FILTER_LOADING,
		payload: {
			loading
		}
	};
};

export const setDetailLoading = (loading: boolean) => {
	return {
		type: SET_DETAIL_LOADING,
		payload: {
			loading
		}
	};
};

export const setChartLoading = (loading: boolean) => {
	return {
		type: SET_CHART_LOADING,
		payload: {
			loading
		}
	};
};

export const setDateSpan = (dates: DateSpan) => {
	return {
		type: SET_DATE_SPAN,
		payload: {
			dateSpan: dates
		}
	};
};

export const setDateRange = (range: DateRange, query?: QueryRange) => {
	switch (range) {
		case 'year':
			return {
				type: SET_DATE_RANGE,
				payload: {
					dateRange: range,
					queryRange: 'month'
				}
			};
		case 'month':
			return {
				type: SET_DATE_RANGE,
				payload: {
					dateRange: range,
					queryRange: 'day'
				}
			};
		case 'Custom':
			return {
				type: SET_DATE_RANGE,
				payload: {
					dateRange: range,
					queryRange: query
				}
			};
		default:
			console.log(`invalid range: ${range}`);
			return {
				type: SET_DATE_RANGE,
				payload: {}
			};
	}
};

export const setZoomIn = (zoom: boolean) => {
	return {
		type: SET_ZOOM_IN,
		payload: zoom
	};
};

export const getUsersAndDevices = (): AppThunk => async (dispatch, getState) => {
	const dateSpan = getDateSpan(getState());
	const filter = getFilter(getState());
	const dateRange = getRange(getState());
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const { to, from, offset } = getOffset(getState());

	dispatch({
		type: GET_USERS_AND_DEVICES
	});

	// console.log(
	// 	`/api/reporting/license-group-usage?from=${dateSpan?.start}${from}&to=${dateSpan?.end}${to}&level=summary&by=${dateRange.queryRange}&id=${licenseGroupId}&view=${filter?.type}&offset=${offset}`
	// );

	try {
		const { data } = await axios.get(
			`/api/reporting/license-group-usage?from=${dateSpan?.start}${from}&to=${dateSpan?.end}${to}&level=summary&by=${dateRange.queryRange}&id=${licenseGroupId}&view=${filter?.type}&offset=${offset}`
		);

		const { lastUpdateDate, report } = data;

		dispatch({
			type: GET_USERS_AND_DEVICES_SUCCESS,
			payload: {
				usersAndDevices: parseUserAndDevicesSummary(report, filter.type)
			}
		});
	} catch (error) {
		dispatch(Actions.alert('Failed to retrieve device and user data', 'error'));
	}
};

export const getReportData = (): AppThunk => async (dispatch, getState) => {
	const dateSpan = getDateSpan(getState());
	const filter = getFilter(getState());
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const { to, from, offset } = getOffset(getState());
	const dateRange = getRange(getState());

	dispatch({
		type: GET_REPORT_DATA
	});
	// console.log(
	// 	`/api/reporting/license-group-usage?from=${dateSpan?.start}${from}&to=${dateSpan?.end}${to}&level=detail&by=${dateRange.queryRange}&id=${licenseGroupId}&view=${filter?.type}&offset=${offset}`
	// );

	try {
		const { data } = await axios.get(
			`/api/reporting/license-group-usage?from=${dateSpan?.start}${from}&to=${dateSpan?.end}${to}&level=detail&by=${dateRange.queryRange}&id=${licenseGroupId}&view=${filter?.type}&offset=${offset}`
		);

		const { lastUpdateDate, report } = data;

		dispatch({
			type: GET_REPORT_DATA_SUCCESS,
			payload: {
				reportData: parseReportData(report, dateRange.queryRange)
			}
		});
	} catch (error) {
		dispatch(Actions.alert('Failed to retrieve report data', 'error'));
	}
};

export const setReportFilter = (filterType: ReportFilterType) => {
	return {
		type: SET_REPORT_FILTER_TYPE,
		payload: {
			filter: filterType
		}
	};
};

export const addReportFilter = (filterId: ReportFilterId) => {
	return {
		type: ADD_REPORT_FILTER,
		payload: {
			id: filterId
		}
	};
};

export const removeReportFilter = (filterId: ReportFilterId) => {
	return {
		type: REMOVE_REPORT_FILTER,
		payload: {
			id: filterId
		}
	};
};

export const clearFilteredReportData = () => {
	return {
		type: CLEAR_FILTERED_REPORT_DATA
	};
};

export const fetchFilteredReportData = (): AppThunk => async (dispatch, getState) => {
	const dateSpan = getDateSpan(getState());
	const { ids, type } = getFilter(getState());
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const { to, from, offset } = getOffset(getState());
	const dateRange = getRange(getState());
	const filterStr = ids.map(filt => filt.id).join(',');

	// console.log(
	// `/api/reporting/license-group-usage?from=${dateSpan?.start}${from}&to=${dateSpan?.end}${to}&level=detail&by=${dateRange.queryRange}&${type}filt=${filterStr}&id=${licenseGroupId}&view=${type}&offset=${offset}`
	// );

	dispatch({
		type: GET_FILTERED_REPORT_DATA
	});

	// if no Filters return empty array for graph
	if (!filterStr) {
		dispatch({
			type: GET_FILTERED_REPORT_DATA_SUCCESS,
			payload: {
				filteredReportData: []
			}
		});
		return;
	}

	try {
		const { data } = await axios.get(
			`/api/reporting/license-group-usage?from=${dateSpan?.start}${from}&to=${dateSpan?.end}${to}&level=detail&by=${dateRange.queryRange}&${type}filt=${filterStr}&id=${licenseGroupId}&view=${type}&offset=${offset}`
		);

		const { lastUpdateDate, report } = data;

		dispatch({
			type: GET_FILTERED_REPORT_DATA_SUCCESS,
			payload: {
				filteredReportData: parseReportData(report, dateRange.queryRange)
			}
		});
	} catch (error) {
		dispatch(Actions.alert('Failed to retrieve filtered report data', 'error'));
	}
};

export const getUserOrDeviceReportData = (id: string | number, name: string): AppThunk => async (
	dispatch,
	getState
) => {
	const dateSpan = getDateSpan(getState());
	const filter = getFilter(getState());
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const { to, from, offset } = getOffset(getState());
	const dateRange = getRange(getState());

	dispatch({
		type: GET_USER_OR_DEVICE_REPORT_DATA
	});

	// console.log(
	// 	`/api/reporting/license-group-usage?from=${dateSpan?.start}${from}&to=${dateSpan?.end}${to}&level=summary&by=${dateRange.queryRange}&${filter.type}filt=${id}&id=${licenseGroupId}&view=${filter.type}&offset=${offset}`
	// );

	try {
		const { data } = await axios.get(
			`/api/reporting/license-group-usage?from=${dateSpan?.start}${from}&to=${dateSpan?.end}${to}&level=summary&by=${dateRange.queryRange}&${filter.type}filt=${id}&id=${licenseGroupId}&view=${filter.type}&offset=${offset}`
		);
		const { lastUpdateDate, report } = data;
		const detail = parseUserOrDeviceReportData(report, filter.type);
		dispatch({
			type: GET_USER_OR_DEVICE_REPORT_DATA_SUCCESS,
			payload: {
				deviceOrUserDetail: {
					name,
					detail
				}
			}
		});
	} catch (error) {
		dispatch(Actions.alert('Failed to retrieve user or device data', 'error'));
	}
};

export const clearUserOrDeviceReportData = () => {
	return {
		type: CLEAR_USER_OR_DEVICE_REPORT_DATA,
		payload: {
			deviceOrUserDetail: undefined
		}
	};
};

// ---------------------------- HELPERS ----------------------------

const parseReportData = (data: Array<any>, queryRange: string) => {
	return _.map(data, value => {
		return {
			date: value[`by_${queryRange}`],
			authCount: parseInt(value.auth_count, 10),
			deviceCount: parseInt(value.devices_used, 10),
			userCount: parseInt(value.users_using, 10)
		};
	});
};

const parseUserAndDevicesSummary = (data: Array<any>, type: string) => {
	const formatted = data.map(item => {
		if (type === 'device') {
			return {
				name: item.device_name,
				id: item.device_id,
				userOrDeviceCount: parseInt(item.users_using, 10),
				authCount: parseInt(item.auth_count, 10)
			};
		}
		return {
			name: item.user_name,
			id: item.user_id,
			userOrDeviceCount: parseInt(item.devices_used, 10),
			authCount: parseInt(item.auth_count, 10)
		};
	});

	return findMostPopular(formatted.filter(item => item.id));
};

const parseUserOrDeviceReportData = (data: Array<any>, type: string) => {
	const formatted = data.map(item => {
		if (type === 'device') {
			return {
				name: item.user_name,
				authCount: parseInt(item.auth_count, 10)
			};
		}
		return {
			name: item.device_name,
			authCount: parseInt(item.auth_count, 10)
		};
	});

	return findMostPopular(formatted);
};
// Maybe need IDS for top, currently included in reponse
const parseTop = (data: Array<any>, type: string) => {
	return data
		.map(item => {
			if (type === 'device') {
				return {
					name: item.device_name,
					authCount: parseInt(item.auth_count, 10)
				};
			}
			return {
				name: item.user_name,
				authCount: parseInt(item.auth_count, 10)
			};
		})
		.sort((a, b) => b.authCount - a.authCount);
};

// find top auth count users or devices bages
const findMostPopular = (formattedData: Array<any>) => {
	formattedData.sort((a, b) => b.authCount - a.authCount);
	let popularityPlacement = 0;
	const ret = formattedData.map((item, idx, arr) => {
		const previousItem = arr[idx - 1];

		if (previousItem?.authCount === item.authCount)
			return {
				...item,
				top: popularityPlacement
			};
		popularityPlacement += 1;
		return {
			...item,
			top: popularityPlacement
		};
	});

	return ret.sort((a, b) => {
		return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
	});
};
