import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const LogoutIcon = (props: SvgIconProps) => (
	<SvgIcon {...props} viewBox="0 0 48 48">
		<g>
			<path
				d="M28.9,28.5c-0.5,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.4,0.7-0.4,1.1v7h-17V11h17v7c0,0.5,0.1,0.8,0.4,1.1c0.3,0.3,0.7,0.4,1.1,0.4
        c0.5,0,0.8-0.1,1.1-0.4c0.3-0.3,0.4-0.7,0.4-1.1v-7c0-0.8-0.3-1.5-0.9-2.1C28.9,8.3,28.2,8,27.4,8h-17C9.6,8,8.9,8.3,8.3,8.9
        C7.7,9.5,7.4,10.2,7.4,11v26c0,0.8,0.3,1.5,0.9,2.1c0.6,0.6,1.3,0.9,2.1,0.9h17c0.8,0,1.5-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1v-7
        c0-0.5-0.1-0.8-0.4-1.1C29.7,28.6,29.3,28.5,28.9,28.5z"
			/>
			<path
				d="M42.2,24c0-0.4-0.2-0.7-0.4-1c0,0,0-0.1-0.1-0.1L35,16.3c-0.3-0.3-0.7-0.5-1.1-0.5c-0.4,0-0.8,0.2-1.1,0.5
      c-0.3,0.3-0.4,0.7-0.4,1.1c0,0.4,0.1,0.7,0.4,1l4.1,4.1H16.4c-0.5,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.4,0.6-0.4,1.1s0.1,0.8,0.4,1.1
      c0.3,0.3,0.7,0.4,1.1,0.4h20.5l-4.1,4.1c-0.3,0.3-0.4,0.7-0.4,1.1c0,0.4,0.1,0.8,0.4,1.1c0.3,0.2,0.6,0.4,1.1,0.4
      c0.4,0,0.7-0.1,1-0.4l6.8-6.8C42,24.7,42.2,24.4,42.2,24z"
			/>
		</g>
	</SvgIcon>
);

export default LogoutIcon;
